import augmentationIn from '../../assets/images/study/augmentationIn.png';
import augmentationOut from '../../assets/images/study/augmentationOut.png';
import deviationIn from '../../assets/images/study/deviationIn.png';
import deviationOut from '../../assets/images/study/deviationOut.png';
import diminutionIn from '../../assets/images/study/diminutionIn.png';
import diminutionOut from '../../assets/images/study/diminutionOut.png';
import mirrorXIn from '../../assets/images/study/mirror_xIn.png';
import mirrorXOut from '../../assets/images/study/mirror_xOut.png';
import mirrorYIn from '../../assets/images/study/mirror_yIn.png';
import mirrorYOut from '../../assets/images/study/mirror_yOut.png';
import reductionToPitchIn from '../../assets/images/study/reduction_to_pitchIn.png';
import reductionToPitchOut from '../../assets/images/study/reduction_to_pitchOut.png';
import reductionToRhythmIn from '../../assets/images/study/reduction_to_rhythmIn.png';
import reductionToRhythmOut from '../../assets/images/study/reduction_to_rhythmOut.png';
import transpositionIn from '../../assets/images/study/transpositionIn.png';
import transpositionOut from '../../assets/images/study/transpositionOut.png';
import augmentationOperator from '../../assets/operators/augmentation.svg';
import deviationOperator from '../../assets/operators/deviation.svg';
import diminutionOperator from '../../assets/operators/diminution.svg';
import mirrorXOperator from '../../assets/operators/mirror-x.svg';
import mirrorYOperator from '../../assets/operators/mirror-y.svg';
import reductionToPitchOperator from '../../assets/operators/reduction-to-pitch.svg';
import reductionToRhythmOperator from '../../assets/operators/reduction-to-rhythm.svg';
import transpositionOperator from '../../assets/operators/transposition.svg';

export interface Occurrence {
  firstOffset: number;
  lastOffset: number;
  voiceNumber: number;
}

export const areOccurrencesEqual = (first: Occurrence, second: Occurrence) => {
  return (
    first.firstOffset === second.firstOffset &&
    first.lastOffset === second.lastOffset &&
    first.voiceNumber === second.voiceNumber
  );
};

export enum Operator {
  AUGMENTATION = 'augmentation',
  DEVIATION = 'deviation',
  DIMINUTION = 'diminution',
  MIRROR_X = 'mirror_x',
  MIRROR_Y = 'mirror_y',
  REDUCTION_TO_PITCH = 'reduction_to_pitch',
  REDUCTION_TO_RHYTHM = 'reduction_to_rhythm',
  TRANSPOSITION = 'transposition'
}

export const OperatorsInOrder = [
  Operator.TRANSPOSITION,
  Operator.MIRROR_X,
  Operator.MIRROR_Y,
  Operator.DIMINUTION,
  Operator.AUGMENTATION,
  Operator.REDUCTION_TO_PITCH,
  Operator.REDUCTION_TO_RHYTHM,
  Operator.DEVIATION
];

export const getIconForOperator = (operator: Operator) => {
  switch (operator) {
    case Operator.AUGMENTATION:
      return augmentationOperator;
    case Operator.DEVIATION:
      return deviationOperator;
    case Operator.DIMINUTION:
      return diminutionOperator;
    case Operator.MIRROR_X:
      return mirrorXOperator;
    case Operator.MIRROR_Y:
      return mirrorYOperator;
    case Operator.REDUCTION_TO_PITCH:
      return reductionToPitchOperator;
    case Operator.REDUCTION_TO_RHYTHM:
      return reductionToRhythmOperator;
    case Operator.TRANSPOSITION:
      return transpositionOperator;
  }
};

export const getSnippetForOperator = (operator: Operator, direction: 'in' | 'out') => {
  switch (operator) {
    case Operator.AUGMENTATION:
      return direction === 'in' ? augmentationIn : augmentationOut;
    case Operator.DEVIATION:
      return direction === 'in' ? deviationIn : deviationOut;
    case Operator.DIMINUTION:
      return direction === 'in' ? diminutionIn : diminutionOut;
    case Operator.MIRROR_X:
      return direction === 'in' ? mirrorXIn : mirrorXOut;
    case Operator.MIRROR_Y:
      return direction === 'in' ? mirrorYIn : mirrorYOut;
    case Operator.REDUCTION_TO_PITCH:
      return direction === 'in' ? reductionToPitchIn : reductionToPitchOut;
    case Operator.REDUCTION_TO_RHYTHM:
      return direction === 'in' ? reductionToRhythmIn : reductionToRhythmOut;
    case Operator.TRANSPOSITION:
      return direction === 'in' ? transpositionIn : transpositionOut;
  }
};

export const isOperatorBidirectional = (operator: Operator) => {
  switch (operator) {
    case Operator.DEVIATION:
    case Operator.REDUCTION_TO_PITCH:
    case Operator.REDUCTION_TO_RHYTHM:
      return false;
    default:
      return true;
  }
};

export interface Pattern {
  color: string;
  connectedToByOccurrences: {
    id: string;
    occurrences: Occurrence[];
  }[];
  connectedToByOperator: {
    id: string;
    byOperator: Operator;
  }[];
  description: string;
  hasBeenQueried: boolean;
  id: string;
  isLocked: boolean;
  isUserDefined: boolean;
  isVisible: boolean;
  label: string;
  length: number;
  occurrences: Occurrence[];
  uniqueOccurrencesCount: number;
  voiceNumber: number;
}

export class PatternLabelGenerator {
  private static labels = ['I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
  private static usedLabels = [];

  static add = (label: string) => {
    const usedLabelIndex = PatternLabelGenerator.usedLabels.findIndex(
      (usedLabel) => usedLabel === label
    );

    PatternLabelGenerator.usedLabels.splice(usedLabelIndex, 1);
    PatternLabelGenerator.labels.push(label);
    PatternLabelGenerator.labels.sort().reverse();
  };
  static next = () => {
    const label = PatternLabelGenerator.labels.pop();

    PatternLabelGenerator.usedLabels.splice(0, 0, label);

    return label;
  };
  static reset = () => {
    PatternLabelGenerator.labels.push(...PatternLabelGenerator.usedLabels);
    PatternLabelGenerator.usedLabels = [];
  };
}

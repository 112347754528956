import { AimOutlined, FullscreenOutlined, RocketTwoTone, UnlockOutlined } from '@ant-design/icons';
import { scaleLinear } from '@visx/scale';
import { Button, Tooltip } from 'antd';
import {
  drag,
  forceCollide,
  forceLink,
  forceSimulation,
  forceX,
  forceY,
  select,
  selectAll,
  Simulation,
  zoom,
  zoomIdentity
} from 'd3';
import { detailedDiff } from 'deep-object-diff';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMeasure, usePrevious } from 'react-use';
import { mostReadable } from 'tinycolor2';
import { fullOpacity, lowOpacity, maximumNodeRadius, minimumNodeRadius } from './constants';
import {
  areOccurrencesEqual,
  getIconForOperator,
  isOperatorBidirectional,
  Occurrence,
  Operator,
  Pattern
} from './models/Pattern';
import PatternConfiguration from './PatternConfiguration';
import { SelectionContext } from './SelectionContext';

interface Edge {
  isBidirectional: boolean;
  source: Node;
  target: Node;
}

interface OccurrenceEdge extends Edge {
  byOccurrences: Occurrence[];
}

interface OperatorEdge extends Edge {
  byOperator?: Operator;
}

export interface Node {
  color: string;
  fx?: number;
  fy?: number;
  id: string;
  label: string;
  x: number;
  y: number;
}

const computeCenterPointBetween = (firstNode: Node, secondNode: Node) => [
  Math.min(firstNode.x, secondNode.x) + Math.abs(firstNode.x - secondNode.x) / 2,
  Math.min(firstNode.y, secondNode.y) + Math.abs(firstNode.y - secondNode.y) / 2
];
const isOccurrenceEdge = (edge: Edge): edge is OccurrenceEdge => 'byOccurrences' in edge;
const requiresLayout = (previous: Node[], next: Node[]) => {
  if (previous === undefined || previous.length !== next.length) return true;

  const changes = detailedDiff(previous, next);

  return (
    Object.values(changes.updated).length > 0 &&
    Object.values(changes.updated).every(
      (update) => Object.keys(update).includes('x') || Object.keys(update).includes('y')
    )
  );
};

export const melodyGraphID = 'melody-graph';

const MelodyGraph = ({
  configuringID,
  hoveredOccurrences,
  hoveredPatternIDs,
  patterns,
  setConfiguringID,
  setHoveredOccurrenceIndex,
  setHoveredOccurrences,
  setHoveredPatternIDs,
  setPatterns,
  setSuggestingID
}: {
  configuringID?: string;
  hoveredOccurrences: Occurrence[];
  hoveredPatternIDs: string[];
  patterns: Pattern[];
  setConfiguringID: Dispatch<SetStateAction<string | undefined>>;
  setHoveredOccurrenceIndex: Dispatch<SetStateAction<number | undefined>>;
  setHoveredOccurrences: Dispatch<SetStateAction<Occurrence[]>>;
  setHoveredPatternIDs: Dispatch<SetStateAction<string[]>>;
  setPatterns: Dispatch<SetStateAction<Pattern[]>>;
  setSuggestingID: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const clickAwayGraphContainer = useRef(null);
  const [edges, setEdges] = useState<(OccurrenceEdge | OperatorEdge)[]>([]);
  const [edgesAnimated, setEdgesAnimated] = useState<(OccurrenceEdge | OperatorEdge)[]>([]);
  const [graphContainer, { height, width }] = useMeasure();
  const graphGroup = useRef(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isPanning, setIsPanning] = useState<boolean>(false);
  const [melodyGraphTransform, setMelodyGraphTransform] = useState<string>('');
  const [nodes, setNodes] = useState<Node[]>([]);
  const [nodesAnimated, setNodesAnimated] = useState<Node[]>([]);
  const previousNodes = usePrevious(nodes);
  const [selection, setSelection] = useContext(SelectionContext);

  const arrowHeadHeight = 4;
  const arrowHeadWidth = 5;
  const occurrenceCounts = patterns
    .filter((pattern) => pattern.isVisible)
    .map((pattern) => pattern.occurrences.length);
  const zoomHandler = zoom();

  const addDragToNodes = (simulation?: Simulation<Node, Edge>) => {
    const dragHandler = drag()
      .on('start', (_event: DragEvent, node: Node) => {
        if (simulation) simulation.alphaTarget(0.01).restart();

        node.fx = node.x;
        node.fy = node.y;

        setIsDragging(true);
        setHoveredPatternIDs([]);
        setHoveredOccurrenceIndex(undefined);
      })
      .on('drag', (event: DragEvent, node: Node) => {
        const nodeID = node.id;
        const pattern = patterns.find((pattern) => pattern.id === nodeID);

        if (pattern?.isLocked) return;

        node.fx = event.x;
        node.fy = event.y;
      })
      .on('end', (_event: DragEvent, node: Node) => {
        if (simulation) simulation.alphaTarget(0);

        delete node.fx;
        delete node.fy;

        setIsDragging(false);
      });

    // @ts-ignore
    dragHandler(selectAll('g.node').data(nodes));
  };
  const resetFocus = (event) => {
    setSelection((oldSelection) => ({
      ...oldSelection,
      focusingID: undefined
    }));
    if (
      configuringID !== undefined &&
      !event.target.classList.contains('node') &&
      !event.target.parentElement.classList.contains('node')
    )
      setConfiguringID(undefined);
  };

  useEffect(() => {
    let center = [width / 2, height / 2];

    if (patterns.length > 0) {
      if (
        nodesAnimated.length > 0 &&
        nodesAnimated.some((node) => !node.id.startsWith('melodic-pattern-'))
      ) {
        const xCoordinates = nodesAnimated.map((node) => node.x);
        const yCoordinates = nodesAnimated.map((node) => node.y);

        const bottom = Math.max(...yCoordinates);
        const left = Math.min(...xCoordinates);
        const right = Math.max(...xCoordinates);
        const top = Math.min(...yCoordinates);
        const height = bottom - top;
        const width = right - left;
        const midPoint = [left + width / 2, top + height / 2];

        if (height > width) {
          center = [right + width / 2, midPoint[1]];
        } else {
          center = [midPoint[0], top - height / 2];
        }
      }

      const patternNodes: Node[] = patterns
        .filter(
          (pattern) =>
            pattern.isVisible &&
            (selection.focusingID === undefined || selection.focusingID === pattern.id)
        )
        .map((pattern) => {
          const node = nodesAnimated.find((nodeAnimated) => nodeAnimated.id === pattern.id) as Node;
          const connectedNode = nodesAnimated.find(
            (nodeAnimated) =>
              nodeAnimated.id ===
              patterns.find((otherPattern) =>
                otherPattern.connectedToByOperator.map(({ id }) => id).includes(pattern.id)
              )?.id
          ) as Node;

          return {
            color: pattern.color,
            fx: node?.fx,
            fy: node?.fy,
            id: pattern.id,
            label: pattern.label,
            x: node ? node.x : connectedNode ? connectedNode.x : center[0],
            y: node ? node.y : connectedNode ? connectedNode.y : center[1]
          };
        });

      setNodes([...patternNodes]);

      let patternOccurrenceConnections = patterns
        .filter(
          (pattern) =>
            pattern.isVisible &&
            (selection.focusingID === undefined || selection.focusingID === pattern.id)
        )
        .flatMap((pattern) =>
          pattern.connectedToByOccurrences
            .filter((target) =>
              patterns.some((pattern) => {
                const sourcePattern = patterns.find(
                  (potentialSourcePattern) => potentialSourcePattern.id === target.id
                );

                return pattern.id === target.id && sourcePattern.isVisible;
              })
            )
            .map((target) => {
              const sourceNode = patternNodes.find((node) => node.id === pattern.id);
              const targetNode = patternNodes.find((node) => node.id === target.id);

              if (sourceNode === undefined || targetNode === undefined) return;

              return {
                byOccurrences: target.occurrences,
                isBidirectional: true,
                source: sourceNode,
                target: targetNode
              };
            })
            .filter((edge) => edge !== undefined)
        );
      patternOccurrenceConnections = patternOccurrenceConnections.filter((connection, index) => {
        const duplicateConnectionIndex = patternOccurrenceConnections.findIndex(
          (potentialConnection) =>
            potentialConnection.source.id === connection.target.id &&
            potentialConnection.target.id === connection.source.id
        );

        return duplicateConnectionIndex === -1 || duplicateConnectionIndex > index;
      });

      const patternOperatorConnections = patterns
        .filter(
          (pattern) =>
            pattern.isVisible &&
            (selection.focusingID === undefined || selection.focusingID === pattern.id)
        )
        .flatMap((pattern) =>
          pattern.connectedToByOperator
            .filter((target) =>
              patterns.some((pattern) => {
                const sourcePattern = patterns.find(
                  (potentialSourcePattern) => potentialSourcePattern.id === target.id
                );

                return pattern.id === target.id && sourcePattern.isVisible;
              })
            )
            .map((target) => {
              const sourceNode = patternNodes.find((node) => node.id === pattern.id);
              const targetNode = patternNodes.find((node) => node.id === target.id);

              if (sourceNode === undefined || targetNode === undefined) return;

              return {
                byOperator: target.byOperator,
                isBidirectional: isOperatorBidirectional(target.byOperator),
                source: sourceNode,
                target: targetNode
              };
            })
            .filter((edge) => edge !== undefined)
        );

      setEdges([...patternOperatorConnections, ...patternOccurrenceConnections]);
    } else {
      const firstNode = {
        color: 'lightGrey',
        id: 'melodic-pattern-0',
        label: 'A',
        x: center[0],
        y: center[1] - 25
      };
      const secondNode = {
        color: 'lightGrey',
        id: 'melodic-pattern-1',
        label: 'B',
        x: center[0] - 25,
        y: center[1]
      };

      setEdges([
        {
          byOperator: Operator.TRANSPOSITION,
          isBidirectional: true,
          source: firstNode,
          target: secondNode
        }
      ]);

      setNodes([
        firstNode,
        secondNode,
        {
          color: 'lightGrey',
          id: 'melodic-pattern-2',
          label: 'C',
          x: center[0] + 50,
          y: center[1] + 50
        }
      ]);
    }
  }, [height, patterns, selection.focusingID, width]);

  useLayoutEffect(() => {
    if (nodes.length === 0) return;

    const center = [width / 2, height / 2];
    const simulation = forceSimulation<Node, Edge>(nodes)
      .alphaDecay(0.06) // Controls for roughly 100 iterations.
      .force('x', forceX(center[0]))
      .force('y', forceY(center[1]))
      .force('collision', forceCollide(50))
      .force(
        'link',
        forceLink<Node, Edge>(edges).id((node) => node.id)
      );

    /*
     * The simulation does not have to run if the graph does not require any animation at the
     * moment. If necessary, the simulation is restarted to animate the graph while dragging later.
     */
    if (!requiresLayout(previousNodes, nodes)) {
      simulation.stop();

      setNodesAnimated([...nodes]);
      setEdgesAnimated([...edges]);
    }

    simulation.on('tick', () => {
      const nodesAnimated = simulation.nodes();

      setNodesAnimated([...nodesAnimated]);
      setEdgesAnimated([...edges]);
    });

    addDragToNodes(simulation);
  }, [edges, height, nodes, width]);

  useLayoutEffect(() => {
    zoomHandler
      .on('start', () => setIsPanning(true))
      .on('zoom', (event) => setMelodyGraphTransform(event.transform))
      .on('end', () => setIsPanning(false));
    select('#zoom').call(zoomHandler).on('dblclick.zoom', null);
  });

  const maximumOccurrenceCount = Math.max(...occurrenceCounts);
  const minimumOccurrenceCount = Math.min(...occurrenceCounts);
  const offsetScale = scaleLinear({
    domain: [minimumOccurrenceCount, maximumOccurrenceCount],
    range: [minimumNodeRadius, maximumNodeRadius],
    round: true
  });
  const radius = (pattern: Pattern) =>
    minimumOccurrenceCount === maximumOccurrenceCount ||
    minimumOccurrenceCount === Infinity ||
    maximumOccurrenceCount === -Infinity
      ? minimumNodeRadius
      : offsetScale(pattern?.occurrences.length ?? minimumOccurrenceCount);

  return (
    <div
      className="w-full relative mb-5 mt-5 flex flex-col"
      ref={clickAwayGraphContainer}
      style={{ height: '-webkit-fill-available', width: 'calc(100% - 1.25rem)' }}
    >
      <div className="absolute left-2 top-2">
        <Button
          className="mr-1 bg-white"
          icon={<FullscreenOutlined />}
          onClick={(event) => {
            const graphBoundingBox = (graphGroup.current as SVGGElement).getBBox();
            const padding = 25;

            const transform = zoomIdentity
              .translate(width / 2, height / 2)
              .scale(
                Math.min(
                  (width - padding) / graphBoundingBox.width,
                  (height - padding) / graphBoundingBox.height
                )
              )
              .translate(
                -graphBoundingBox.x - graphBoundingBox.width / 2,
                -graphBoundingBox.y - graphBoundingBox.height / 2
              );

            setMelodyGraphTransform(transform.toString());
            zoomHandler.transform(select('#zoom'), transform);

            event.stopPropagation();
          }}
          shape="circle"
          size="small"
        />
        <Button
          className={`mr-1 ${
            patterns.filter((pattern) => pattern.isVisible).some((pattern) => pattern.isLocked)
              ? undefined
              : 'bg-white'
          }`}
          disabled={
            !patterns.filter((pattern) => pattern.isVisible).some((pattern) => pattern.isLocked)
          }
          icon={<UnlockOutlined />}
          onClick={(event) => {
            setPatterns((oldPatterns) => {
              oldPatterns
                .filter((oldPattern) => oldPattern.isVisible)
                .forEach((oldPattern) => (oldPattern.isLocked = false));

              return [...oldPatterns];
            });
            setNodes(
              nodes.map((node) => {
                delete node.fx;
                delete node.fy;

                return node;
              })
            );

            event.stopPropagation();
          }}
          shape="circle"
          size="small"
          type={
            patterns.filter((pattern) => pattern.isVisible).some((pattern) => pattern.isLocked)
              ? 'primary'
              : 'default'
          }
        />
        <Button
          className={selection.focusingID === undefined ? 'bg-white' : undefined}
          disabled={configuringID === undefined}
          icon={<AimOutlined />}
          onClick={(event) => {
            if (selection.focusingID !== undefined) {
              setSelection((oldSelection) => ({
                ...oldSelection,
                focusingID: undefined
              }));
            } else {
              setSelection((oldSelection) => ({
                ...oldSelection,
                focusingID: configuringID
              }));
            }

            event.stopPropagation();
          }}
          shape="circle"
          size="small"
          type={selection.focusingID === undefined ? 'default' : 'primary'}
        />
      </div>
      <div
        className="h-full w-full border border-dashed border-gray-300"
        id={melodyGraphID}
        ref={graphContainer}
      >
        <svg className="cursor-move" height="100%" onClick={resetFocus} width="100%">
          <defs>
            {edgesAnimated.map((edge, index) => {
              const targetNode = edge.target;
              const targetPattern = patterns.find((pattern) => pattern.id === targetNode.id);

              // Better than filtering the edges beforehand to keep the index consistent with the rendering of the edges.
              if (edge.isBidirectional) return null;

              return (
                <marker
                  id={`arrow-head-${index}`}
                  key={index}
                  markerWidth={arrowHeadWidth}
                  markerHeight={arrowHeadHeight}
                  refX={radius(targetPattern) / 2 + arrowHeadWidth}
                  refY={arrowHeadHeight / 2}
                  orient="auto"
                >
                  <polygon
                    fill="lightGrey"
                    points={`0 0, ${arrowHeadWidth} ${arrowHeadHeight / 2}, 0 ${arrowHeadHeight}`}
                  />
                </marker>
              );
            })}
          </defs>
          <rect className="fill-transparent" height={height} id="zoom" width={width} />
          <g ref={graphGroup} transform={melodyGraphTransform}>
            {edgesAnimated.map((edge, index) => (
              <MelodyGraphEdge
                edge={edge}
                hoveredOccurrences={hoveredOccurrences}
                hoveredPatternIDs={hoveredPatternIDs}
                index={index}
                key={index}
                setHoveredOccurrences={setHoveredOccurrences}
              />
            ))}
            {nodesAnimated.map((node, index) => {
              const pattern = patterns.find((pattern) => pattern.id === node.id);

              return (
                <MelodyGraphNode
                  configuringID={configuringID}
                  hoveredOccurrences={hoveredOccurrences}
                  hoveredPatternIDs={hoveredPatternIDs}
                  isDragging={isDragging}
                  isPanning={isPanning}
                  key={index}
                  node={node}
                  pattern={pattern}
                  radius={radius(pattern)}
                  setConfiguringID={setConfiguringID}
                  setHoveredOccurrenceIndex={setHoveredOccurrenceIndex}
                  setHoveredPatternIDs={setHoveredPatternIDs}
                />
              );
            })}
          </g>
        </svg>
      </div>
      <PatternConfiguration
        configuringID={configuringID}
        nodes={nodes}
        patterns={patterns}
        setConfiguringID={setConfiguringID}
        setNodes={setNodes}
        setPatterns={setPatterns}
        setSuggestingID={setSuggestingID}
      />
    </div>
  );
};

const MelodyGraphEdge = ({
  edge,
  hoveredOccurrences,
  hoveredPatternIDs,
  index,
  setHoveredOccurrences
}: {
  edge: OccurrenceEdge | OperatorEdge;
  hoveredOccurrences: Occurrence[];
  hoveredPatternIDs: string[];
  index: number;
  setHoveredOccurrences: Dispatch<SetStateAction<Occurrence[]>>;
}) => {
  const center = computeCenterPointBetween(edge.source, edge.target);
  const circleRadius = 3;
  const imageSize = 16;
  const shouldBeTransparent =
    hoveredPatternIDs.length > 0 ||
    (hoveredOccurrences.length > 0 &&
      (!isOccurrenceEdge(edge) ||
        !edge.byOccurrences.some((occurrence) =>
          hoveredOccurrences.some((hoveredOccurrence) =>
            areOccurrencesEqual(hoveredOccurrence, occurrence)
          )
        )));

  const { t: translate } = useTranslation();

  return (
    <g>
      <line
        markerEnd={edge.isBidirectional ? '' : `url(#arrow-head-${index})`}
        stroke="lightGrey"
        strokeDasharray={isOccurrenceEdge(edge) ? '4 2' : ''}
        strokeWidth={2}
        style={{ strokeOpacity: shouldBeTransparent ? lowOpacity : fullOpacity }}
        x1={edge.source.x}
        y1={edge.source.y}
        x2={edge.target.x}
        y2={edge.target.y}
      />
      {isOccurrenceEdge(edge) ? (
        <Tooltip
          onOpenChange={(open) => setHoveredOccurrences(open ? edge.byOccurrences : [])}
          title={translate(`melodyGraph.commonOccurrencesEdge`, {
            count: edge.byOccurrences.length
          })}
        >
          <circle
            cx={center[0]}
            cy={center[1]}
            fill="lightGrey"
            r={circleRadius}
            opacity={shouldBeTransparent ? lowOpacity : fullOpacity}
            stroke="grey"
            style={{
              cursor: 'help'
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip title={translate(`suggestions.operators.${edge.byOperator}.title`)}>
          <image
            height={imageSize}
            href={getIconForOperator(edge.byOperator)}
            opacity={shouldBeTransparent ? lowOpacity : fullOpacity}
            style={{
              cursor: 'help',
              outline: '1px solid lightGrey'
            }}
            width={imageSize}
            x={center[0] - imageSize / 2}
            y={center[1] - imageSize / 2}
          />
        </Tooltip>
      )}
    </g>
  );
};

const MelodyGraphNode = ({
  configuringID,
  hoveredOccurrences,
  hoveredPatternIDs,
  isDragging,
  isPanning,
  node,
  pattern,
  radius,
  setConfiguringID,
  setHoveredOccurrenceIndex,
  setHoveredPatternIDs
}: {
  configuringID: string | undefined;
  hoveredOccurrences: Occurrence[];
  hoveredPatternIDs: string[];
  isDragging: boolean;
  isPanning: boolean;
  node: Node;
  pattern: Pattern;
  radius: number;
  setConfiguringID: Dispatch<SetStateAction<string>>;
  setHoveredOccurrenceIndex: Dispatch<SetStateAction<number | undefined>>;
  setHoveredPatternIDs: Dispatch<SetStateAction<string[]>>;
}) => {
  const [selection, setSelection] = useContext(SelectionContext);

  const hasFixedPosition = node.fx !== undefined && node.fy !== undefined;
  const shouldBeTransparent =
    (hoveredPatternIDs.length > 0 && !hoveredPatternIDs.includes(node.id)) ||
    (hoveredOccurrences.length > 0 &&
      pattern.occurrences.every((occurrence) =>
        hoveredOccurrences.every(
          (hoveredOccurrence) => !areOccurrencesEqual(hoveredOccurrence, occurrence)
        )
      ));

  const stopHovering = () => {
    setHoveredOccurrenceIndex(undefined);
    setHoveredPatternIDs([]);
  };

  return (
    <g
      className="cursor-pointer node"
      onClick={() => {
        stopHovering();
        setConfiguringID(node.id);
      }}
      onDoubleClick={() => {
        stopHovering();

        if (selection.focusingID === undefined || selection.focusingID !== node.id) {
          setConfiguringID(node.id);
          setSelection((oldSelection) => ({
            ...oldSelection,
            focusingID: node.id
          }));
        } else {
          setSelection((oldSelection) => ({
            ...oldSelection,
            focusingID: undefined
          }));
        }
      }}
      onMouseEnter={() => !isDragging && !isPanning && setHoveredPatternIDs([node.id])}
      onMouseLeave={stopHovering}
      style={{ fillOpacity: shouldBeTransparent ? lowOpacity : fullOpacity }}
    >
      <circle
        cx={hasFixedPosition ? node.fx : node.x}
        cy={hasFixedPosition ? node.fy : node.y}
        fill={node.color}
        r={radius}
        stroke={
          (configuringID !== undefined && configuringID === pattern?.id) || pattern?.isLocked
            ? 'grey'
            : undefined
        }
        strokeDasharray={pattern?.isLocked ? 4 : undefined}
        strokeWidth={2}
      />
      <text
        alignmentBaseline="middle"
        fill={mostReadable(node.color, ['#808080'], {
          includeFallbackColors: true,
          level: 'AAA',
          size: 'small'
        }).toHexString()}
        fontSize="xx-small"
        textAnchor="middle"
        x={hasFixedPosition ? node.fx : node.x}
        y={hasFixedPosition ? node.fy : node.y}
      >
        {node.label}
      </text>
      {pattern && !pattern.isUserDefined && (
        <foreignObject
          className="flex"
          height={10}
          width={10}
          x={(hasFixedPosition ? node.fx : node.x) + Math.cos(Math.PI / 3) * radius + 4}
          y={(hasFixedPosition ? node.fy : node.y) - Math.sin(Math.PI / 3) * radius - 4}
        >
          <RocketTwoTone className="mr-1" style={{ fontSize: 9 }} twoToneColor="orange" />
        </foreignObject>
      )}
    </g>
  );
};

export default MelodyGraph;

import { isEqual, uniqWith } from 'lodash';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { NoteElementSelection } from '../App';
import { getAPIURL } from '../host';
import { getAbsoluteOffset } from '../models/Note';
import { isOperatorBidirectional, Occurrence, Operator, Pattern } from '../models/Pattern';
import { OperatorCondition, useStudy } from '../StudyContext';
import { StudyStep } from '../StudyControls';
import { ThemeContext } from '../ThemeContext';

enum ACTION {
  ADD_PATTERN = 'ADD_PATTERN',
  CONNECT_PATTERNS = 'CONNECT_PATTERNS',
  EXPAND_PATTERN = 'EXPAND_PATTERN',
  UPDATE_DEVIATION_OPERATOR = 'UPDATE_DEVIATION_OPERATOR'
}

enum EVENT {
  CONNECTIONS_FOUND = 'CONNECTIONS_FOUND',
  OCCURRENCE_FOUND = 'OCCURRENCE_FOUND',
  PATTERN_FOUND = 'PATTERN_FOUND',
  PATTERN_OCCURRENCES_COUNTED = 'PATTERN_OCCURRENCES_COUNTED',
  SEARCH_FINISHED = 'SEARCH_FINISHED'
}

const usePatterns = (
  musicSheetID: string,
  noteElements: NoteElementSelection,
  pattern: Pattern,
  deviationPercentage: number,
  patterns: Pattern[],
  setPattern: Dispatch<SetStateAction<Pattern>>,
  setPatterns: Dispatch<SetStateAction<Pattern[]>>
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = useState<Map<Operator, boolean>>(
    new Map(Object.values(Operator).map((operator) => [operator, false]))
  );
  const { lastJsonMessage, sendJsonMessage, readyState } = useWebSocket(
    `${getAPIURL(true)}/patterns/${musicSheetID}`,
    {},
    isOpen
  );
  const study = useStudy();
  const [theme] = useContext(ThemeContext);

  const isWithoutOperators =
    (study.currentStep === StudyStep.ANALYSIS_TASK_1 &&
      study.firstMusicSheetCondition === OperatorCondition.WITHOUT_OPERATORS) ||
    (study.currentStep === StudyStep.ANALYSIS_TASK_2 &&
      study.secondMusicSheetCondition === OperatorCondition.WITHOUT_OPERATORS);

  useEffect(() => {
    setIsOpen(musicSheetID !== undefined && pattern !== undefined);
  }, [musicSheetID, pattern]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN && pattern) {
      if (pattern.occurrences.length > 0) {
        const occurrence = pattern.occurrences[0];

        if (!isWithoutOperators) {
          setLoadingStatus(new Map(Object.values(Operator).map((operator) => [operator, true])));
        }

        if (pattern.isUserDefined) {
          sendJsonMessage({
            action: ACTION.ADD_PATTERN,
            data: {
              deviationPercentage,
              firstOffset: occurrence.firstOffset,
              lastOffset: occurrence.lastOffset,
              length: pattern.length,
              onlyOccurrences: isWithoutOperators,
              voiceNumber: occurrence.voiceNumber
            }
          });
        } else if (!pattern.hasBeenQueried) {
          let occurrencesToExclude = [
            ...pattern.occurrences,
            ...pattern.connectedToByOperator.flatMap((connection) => {
              const connectedPattern = patterns.find(
                (existingPattern) => existingPattern.id === connection.id
              );

              return connectedPattern.occurrences;
            }),
            ...patterns.flatMap((possiblyConnectedPattern) => {
              const isConnectedToPattern = possiblyConnectedPattern.connectedToByOperator.some(
                (connection) => connection.id === pattern.id
              );

              if (isConnectedToPattern) {
                return possiblyConnectedPattern.occurrences;
              }

              return [];
            })
          ];
          occurrencesToExclude = uniqWith(occurrencesToExclude, isEqual);

          sendJsonMessage({
            action: ACTION.EXPAND_PATTERN,
            data: {
              deviationPercentage,
              firstOffset: occurrence.firstOffset,
              lastOffset: occurrence.lastOffset,
              length: pattern.length,
              matchesToExclude: occurrencesToExclude.map((occurrence) => ({
                firstOffset: occurrence.firstOffset,
                lastOffset: occurrence.lastOffset,
                voiceNumber: occurrence.voiceNumber
              })),
              voiceNumber: occurrence.voiceNumber
            }
          });
          setPatterns((oldPatterns) => {
            const expandedPattern = oldPatterns.find((oldPattern) => oldPattern.id === pattern.id);

            expandedPattern.hasBeenQueried = true;

            return [...oldPatterns];
          });
        }
      }
    }
  }, [pattern, readyState]);

  useEffect(() => {
    if (lastJsonMessage !== null && pattern !== undefined) {
      const event = lastJsonMessage['event'];

      switch (event) {
        case EVENT.OCCURRENCE_FOUND: {
          const firstOffset = lastJsonMessage['data']['firstOffset'];
          const lastOffset = lastJsonMessage['data']['lastOffset'];
          const voiceNumber = lastJsonMessage['data']['voiceNumber'];

          setPatterns((oldPatterns) => {
            const occurrence: Occurrence = { firstOffset, lastOffset, voiceNumber };
            const oldPattern = oldPatterns.find((oldPattern) => oldPattern.id === pattern.id);

            oldPattern.occurrences.push(occurrence);
            if (theme.colorVoices)
              oldPattern.color = theme.getVoiceColor(occursFirstInVoice(oldPattern));

            return [...oldPatterns];
          });

          break;
        }
        case EVENT.PATTERN_FOUND: {
          const id = lastJsonMessage['data']['id'];

          const firstOffset = lastJsonMessage['data']['firstOffset'];
          const lastOffset = lastJsonMessage['data']['lastOffset'];
          const operator = lastJsonMessage['data']['operator'];
          const voiceNumber = lastJsonMessage['data']['voiceNumber'];

          const occurrence: Occurrence = {
            firstOffset,
            lastOffset,
            voiceNumber
          };

          setPatterns((oldPatterns) => {
            const existingPattern = oldPatterns.find((oldPattern) => oldPattern.id === id);
            if (existingPattern === undefined) {
              const noteContexts = noteElements
                .filter((noteContext) => noteContext.voiceNumber === voiceNumber)
                .data();
              const firstNoteContextIndex = noteContexts.findIndex(
                (noteContext) => getAbsoluteOffset(noteContext.note.sourceNote) === firstOffset
              );
              const lastNoteContextIndex = noteContexts.findIndex(
                (noteContext) => getAbsoluteOffset(noteContext.note.sourceNote) === lastOffset
              );
              const patternLength = lastNoteContextIndex - firstNoteContextIndex + 1;

              const labelPattern = new RegExp(`^${pattern.label}'*$`);
              const patternWithLongestLabel = oldPatterns
                .filter((oldPattern) => oldPattern.label.match(labelPattern))
                .sort(
                  (firstPattern, secondPattern) =>
                    -(firstPattern.label.length - secondPattern.label.length)
                )[0];

              const newPattern: Pattern = {
                color: theme.colorByVoice
                  ? theme.getVoiceColor(voiceNumber)
                  : theme.availablePatternColors.pop(),
                connectedToByOccurrences: [],
                connectedToByOperator: isOperatorBidirectional(operator)
                  ? [{ id: pattern.id, byOperator: operator }]
                  : [],
                description: undefined,
                hasBeenQueried: false,
                id,
                isLocked: false,
                isUserDefined: false,
                isVisible: false,
                label: `${patternWithLongestLabel.label}'`,
                length: patternLength,
                occurrences: [occurrence],
                uniqueOccurrencesCount: 1,
                voiceNumber
              };
              const oldPattern = oldPatterns.find((oldPattern) => oldPattern.id === pattern.id);
              oldPattern.connectedToByOperator.push({ id, byOperator: operator });

              return [...oldPatterns, newPattern];
            } else {
              const oldPattern = oldPatterns.find(
                (oldPattern) => oldPattern.id === existingPattern.id
              );

              oldPattern.occurrences.push(occurrence);
              if (theme.colorByVoice)
                oldPattern.color = theme.getVoiceColor(occursFirstInVoice(oldPattern));

              return [...oldPatterns];
            }
          });

          break;
        }
        case EVENT.PATTERN_OCCURRENCES_COUNTED: {
          const patternID = lastJsonMessage['data']['id'];
          const uniqueOccurrencesCount = lastJsonMessage['data']['uniqueCount'];

          setPatterns((oldPatterns) => {
            const oldPattern = oldPatterns.find(
              (oldPattern) => oldPattern.id === (patternID || pattern.id)
            );

            if (oldPattern === undefined) return oldPatterns;

            oldPattern.uniqueOccurrencesCount = uniqueOccurrencesCount;

            return [...oldPatterns];
          });

          break;
        }
      }
    }
  }, [lastJsonMessage, noteElements, pattern]);

  useEffect(() => {
    if (lastJsonMessage !== null && patterns.length > 0) {
      const event = lastJsonMessage['event'];

      switch (event) {
        case EVENT.SEARCH_FINISHED: {
          const type = lastJsonMessage['data'];

          if (type) {
            const operator = type['operator'] as Operator;

            setLoadingStatus((previousLoadingStatus) => {
              previousLoadingStatus.set(operator, false);

              return new Map(previousLoadingStatus);
            });
          } else {
            setLoadingStatus(new Map(Object.values(Operator).map((operator) => [operator, false])));

            sendJsonMessage({
              action: ACTION.CONNECT_PATTERNS,
              data: patterns.map((pattern) => ({
                id: pattern.id,
                occurrences: pattern.occurrences.map((occurrence) => ({
                  firstOffset: occurrence.firstOffset,
                  lastOffset: occurrence.lastOffset,
                  voiceNumber: occurrence.voiceNumber
                }))
              }))
            });
          }

          break;
        }
      }
    }
  }, [lastJsonMessage, patterns]);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      const event = lastJsonMessage['event'];

      switch (event) {
        case EVENT.CONNECTIONS_FOUND: {
          const patternConnections = lastJsonMessage['data'];

          setPatterns((oldPatterns) => {
            patternConnections.forEach((patternConnection) => {
              const firstPattern = oldPatterns.find(
                (pattern) => pattern.id === patternConnection.firstPatternID
              );
              const secondPattern = oldPatterns.find(
                (pattern) => pattern.id === patternConnection.secondPatternID
              );
              const isFirstConnectedToSecond = firstPattern.connectedToByOperator.some(
                (connection) => connection.id === secondPattern.id
              );
              const isSecondConnectedToFirst = secondPattern.connectedToByOperator.some(
                (connection) => connection.id === firstPattern.id
              );

              if (
                firstPattern &&
                secondPattern &&
                !isFirstConnectedToSecond &&
                !isSecondConnectedToFirst
              ) {
                const firstPatternConnection = {
                  id: secondPattern.id,
                  occurrences: patternConnection.occurrences
                };
                const secondPatternConnection = {
                  id: firstPattern.id,
                  occurrences: patternConnection.occurrences
                };

                firstPattern.connectedToByOccurrences.push(firstPatternConnection);
                secondPattern.connectedToByOccurrences.push(secondPatternConnection);
              }
            });

            return [...oldPatterns];
          });

          break;
        }
      }
    }
  }, [lastJsonMessage]);

  return {
    loadingStatus,
    updateDeviationOperator: (configuringID: string, deviationPercentage: number) => {
      setLoadingStatus((previousLoadingStatus) =>
        new Map(previousLoadingStatus).set(Operator.DEVIATION, true)
      );
      setPatterns((oldPatterns) => {
        const configuringPattern = oldPatterns.find((pattern) => pattern.id === configuringID);

        if (configuringPattern) {
          const patternsToDelete = configuringPattern.connectedToByOperator
            .filter((connection) => connection.byOperator === Operator.DEVIATION)
            .map((connection) => oldPatterns.find((oldPattern) => oldPattern.id === connection.id))
            // Only delete the pattern if there is no other connection than the deviation to it.
            .filter((patternToDelete) =>
              patternToDelete.connectedToByOperator.every(
                (connection) => connection.byOperator === Operator.DEVIATION
              )
            );
          const occurrencesToDelete = patternsToDelete.flatMap(
            (patternToDelete) => patternToDelete.occurrences
          );

          configuringPattern.connectedToByOperator =
            configuringPattern.connectedToByOperator.filter(
              (connection) => connection.byOperator !== Operator.DEVIATION
            );

          // Remove the occurrences to be deleted from the connections by occurrences.
          configuringPattern.connectedToByOccurrences.forEach((connection) => {
            connection.occurrences = connection.occurrences.filter(
              (occurrence) =>
                !occurrencesToDelete.some((occurrenceToDelete) => occurrence === occurrenceToDelete)
            );

            // Remove the occurrences to be delete from the other pattern's connection reference as well.
            const otherPattern = oldPatterns.find((oldPattern) => oldPattern.id === connection.id);
            otherPattern.connectedToByOccurrences.forEach((otherConnection) => {
              otherConnection.occurrences = otherConnection.occurrences.filter(
                (occurrence) =>
                  !occurrencesToDelete.some(
                    (occurrenceToDelete) => occurrence === occurrenceToDelete
                  )
              );

              // If no occurrences remain, also remove the connection.
              if (otherConnection.occurrences.length === 0) {
                otherPattern.connectedToByOccurrences =
                  otherPattern.connectedToByOccurrences.filter(
                    (obsoleteConnection) => obsoleteConnection.id !== otherConnection.id
                  );
              }
            });

            // If no occurrences remain, also remove the connection.
            if (connection.occurrences.length === 0)
              configuringPattern.connectedToByOccurrences =
                configuringPattern.connectedToByOccurrences.filter(
                  (obsoleteConnection) => obsoleteConnection.id !== connection.id
                );
          });

          oldPatterns = oldPatterns.filter((oldPattern) => !patternsToDelete.contains(oldPattern));
        }

        return [...oldPatterns];
      });

      const occurrence = pattern.occurrences[0];
      sendJsonMessage({
        action: ACTION.UPDATE_DEVIATION_OPERATOR,
        data: {
          deviationPercentage,
          firstOffset: occurrence.firstOffset,
          lastOffset: occurrence.lastOffset,
          length: pattern.length,
          voiceNumber: occurrence.voiceNumber
        }
      });
    }
  };
};

export const occursFirstInVoice = (pattern: Pattern) => {
  const earliestOffset = Math.min(
    ...pattern.occurrences.map((occurrence) => occurrence.firstOffset)
  );
  const firstOccurrences = pattern.occurrences.filter(
    (occurrence) => occurrence.firstOffset === earliestOffset
  );

  return Math.min(...firstOccurrences.map((occurrence) => occurrence.voiceNumber));
};

export default usePatterns;

import axios from 'axios';
import { isEqual } from 'lodash';
import { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';
import useCountDown from 'react-countdown-hook';
import { useAsync, useLocalStorage, useUpdateEffect } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { timeToCompleteTask } from './constants';
import { getAPIURL } from './host';
import { Pattern } from './models/Pattern';
import { AnalysisTask } from './study-control-steps/AnalysisTasks';
import { StudyStep } from './StudyControls';

export enum OperatorCondition {
  WITH_OPERATORS = 'with_operators',
  WITHOUT_OPERATORS = 'without_operators'
}

enum StudyAction {
  ANSWER_DEMOGRAPHIC_QUESTIONNAIRE,
  ANSWER_INTERACTION_QUESTIONNAIRE,
  ANSWER_SUS_QUESTIONNAIRE,
  ANSWER_TLX_QUESTIONNAIRE_FIRST_TASK,
  ANSWER_TLX_QUESTIONNAIRE_SECOND_TASK,
  CHANGE_PATTERNS,
  NEXT_STEP,
  RESUME_COUNTDOWN,
  SET_STUDY_CONFIGURATION,
  SET_UTM_TAGS,
  START_COUNTDOWN,
  STOP_COUNTDOWN,
  TICK_COUNTDOWN,
  TRIGGER_NASA_TLX,
  WITHDRAW
}

export interface DemographicQuestionnaire {
  age: string;
  contact: string;
  experienceWithDataVisualization: string;
  experienceWithMelodyAnalysis: string;
  experienceWithMusicTheory: string;
  gender: string;
  highestLevelOfEducation: string;
  isAbleToReadSheetMusic: boolean;
  manualPatternAnnotation: number;
}

export interface InteractionQuestionnaire {
  dislikedAboutPrototype: string;
  likedAboutPrototype: string;
  magicWandPrototype: string;
  melodicOperatorsAugmentation: number;
  melodicOperatorsDeviation: number;
  melodicOperatorsDiminution: number;
  melodicOperatorsMirrorX: number;
  melodicOperatorsMirrorY: number;
  melodicOperatorsReductionToPitch: number;
  melodicOperatorsReductionToRhythm: number;
  melodicOperatorsUsability: number;
  melodicOperatorsUsefulness: number;
  melodicOperatorsTransposition: number;
  melodicOperatorsTrust: number;
  melodyGraphUsability: number;
  melodyGraphUsefulness: number;
  melodyTimelineUsability: number;
  melodyTimelineUsefulness: number;
  prototypeApplications: string;
  prototypeEffort: number;
  prototypeUsability: number;
  prototypeUsefulness: number;
  patternHighlightingUsability: number;
  patternHighlightingUsefulness: number;
  reasonsForPatterns: string;
}

export interface SUSQuestionnaire {
  confident: number;
  cumbersome: number;
  easyToUse: number;
  learnALot: number;
  learnQuickly: number;
  needAssistance: number;
  unnecessarilyComplex: number;
  useFrequently: number;
  tooMuchInconsistency: number;
  wellIntegrated: number;
}

export interface TLXQuestionnaire {
  effort: number;
  frustration: number;
  isOpen: boolean;
  mentalDemand: number;
  performance: number;
  physicalDemand: number;
  temporalDemand: number;
}

interface StudyState {
  beganAt: Date;
  currentStep: number;
  demographicQuestionnaire: DemographicQuestionnaire;
  endedAt: Date;
  firstMusicSheetCondition: OperatorCondition;
  firstMusicSheetID: string;
  id: string;
  interactionQuestionnaire: InteractionQuestionnaire;
  isCountdownRunning: boolean;
  patterns: Pattern[];
  patternsFirstTask: Pattern[];
  patternsSecondTask: Pattern[];
  secondMusicSheetCondition: OperatorCondition;
  secondMusicSheetID: string;
  susQuestionnaire: SUSQuestionnaire;
  timeElapsedFirstTask: number;
  timeElapsedSecondTask: number;
  timeLeft: number;
  tlxQuestionnaireFirstTask: TLXQuestionnaire;
  tlxQuestionnaireSecondTask: TLXQuestionnaire;
  utmCampaign: string;
  utmMedium: string;
  utmSource: string;
  hasWithdrawn: boolean;
  withdrawalReason: string;
}

const defaultStudyState: StudyState = {
  beganAt: undefined,
  currentStep: StudyStep.WELCOME,
  demographicQuestionnaire: {
    age: undefined,
    contact: undefined,
    experienceWithDataVisualization: undefined,
    experienceWithMelodyAnalysis: undefined,
    experienceWithMusicTheory: undefined,
    gender: undefined,
    highestLevelOfEducation: undefined,
    isAbleToReadSheetMusic: undefined,
    manualPatternAnnotation: undefined
  },
  endedAt: undefined,
  firstMusicSheetCondition: undefined,
  firstMusicSheetID: undefined,
  id: uuidv4(),
  interactionQuestionnaire: {
    dislikedAboutPrototype: undefined,
    likedAboutPrototype: undefined,
    magicWandPrototype: undefined,
    melodicOperatorsAugmentation: undefined,
    melodicOperatorsDeviation: undefined,
    melodicOperatorsDiminution: undefined,
    melodicOperatorsMirrorX: undefined,
    melodicOperatorsMirrorY: undefined,
    melodicOperatorsReductionToPitch: undefined,
    melodicOperatorsReductionToRhythm: undefined,
    melodicOperatorsUsability: undefined,
    melodicOperatorsUsefulness: undefined,
    melodicOperatorsTransposition: undefined,
    melodicOperatorsTrust: undefined,
    melodyGraphUsability: undefined,
    melodyGraphUsefulness: undefined,
    melodyTimelineUsability: undefined,
    melodyTimelineUsefulness: undefined,
    prototypeApplications: undefined,
    prototypeEffort: undefined,
    prototypeUsability: undefined,
    prototypeUsefulness: undefined,
    patternHighlightingUsability: undefined,
    patternHighlightingUsefulness: undefined,
    reasonsForPatterns: undefined
  },
  isCountdownRunning: false,
  patterns: [],
  patternsFirstTask: [],
  patternsSecondTask: [],
  secondMusicSheetCondition: undefined,
  secondMusicSheetID: undefined,
  susQuestionnaire: {
    confident: undefined,
    cumbersome: undefined,
    easyToUse: undefined,
    learnALot: undefined,
    learnQuickly: undefined,
    needAssistance: undefined,
    unnecessarilyComplex: undefined,
    useFrequently: undefined,
    tooMuchInconsistency: undefined,
    wellIntegrated: undefined
  },
  timeElapsedFirstTask: undefined,
  timeElapsedSecondTask: undefined,
  timeLeft: timeToCompleteTask,
  tlxQuestionnaireFirstTask: {
    effort: undefined,
    frustration: undefined,
    isOpen: false,
    mentalDemand: undefined,
    performance: undefined,
    physicalDemand: undefined,
    temporalDemand: undefined
  },
  tlxQuestionnaireSecondTask: {
    effort: undefined,
    frustration: undefined,
    isOpen: false,
    mentalDemand: undefined,
    performance: undefined,
    physicalDemand: undefined,
    temporalDemand: undefined
  },
  utmCampaign: undefined,
  utmMedium: undefined,
  utmSource: undefined,
  hasWithdrawn: false,
  withdrawalReason: undefined
};

const replaceNullWithUndefined = (object) => {
  for (const key in object) {
    if (object[key] === null) {
      object[key] = undefined;
    } else if (typeof object[key] === 'object') {
      replaceNullWithUndefined(object[key]);
    }
  }
};
const studyStateReducer = (study: StudyState, action: { payload: any; type: StudyAction }) => {
  switch (action.type) {
    case StudyAction.ANSWER_DEMOGRAPHIC_QUESTIONNAIRE:
      return { ...study, demographicQuestionnaire: action.payload };
    case StudyAction.ANSWER_INTERACTION_QUESTIONNAIRE:
      return { ...study, interactionQuestionnaire: action.payload };
    case StudyAction.ANSWER_SUS_QUESTIONNAIRE:
      return { ...study, susQuestionnaire: action.payload };
    case StudyAction.ANSWER_TLX_QUESTIONNAIRE_FIRST_TASK:
      return { ...study, tlxQuestionnaireFirstTask: action.payload };
    case StudyAction.ANSWER_TLX_QUESTIONNAIRE_SECOND_TASK:
      return { ...study, tlxQuestionnaireSecondTask: action.payload };
    case StudyAction.CHANGE_PATTERNS:
      if (typeof action.payload === 'function') {
        return { ...study, patterns: action.payload(study.patterns) };
      } else {
        return { ...study, patterns: action.payload };
      }
    case StudyAction.NEXT_STEP:
      if (study.currentStep === StudyStep.WELCOME) {
        study.beganAt = new Date();
      } else if (study.currentStep === StudyStep.PLAYGROUND) {
        study.patterns = [];
      } else if (study.currentStep === StudyStep.ANALYSIS_TASK_1) {
        study.patternsFirstTask = study.patterns;
        study.patterns = [];
        study.timeElapsedFirstTask = timeToCompleteTask - study.timeLeft;
        study.tlxQuestionnaireFirstTask.isOpen = false;
      } else if (study.currentStep === StudyStep.ANALYSIS_TASK_2) {
        study.patternsSecondTask = study.patterns;
        study.patterns = [];
        study.timeElapsedSecondTask = timeToCompleteTask - study.timeLeft;
        study.tlxQuestionnaireSecondTask.isOpen = false;
      } else if (study.currentStep === StudyStep.INTERACTION_QUESTIONNAIRE) {
        study.endedAt = new Date();
      }

      return {
        ...study,
        currentStep: study.currentStep + 1,
        isCountdownRunning: false,
        timeLeft: timeToCompleteTask
      };
    case StudyAction.RESUME_COUNTDOWN:
    case StudyAction.START_COUNTDOWN:
      return { ...study, isCountdownRunning: true };
    case StudyAction.SET_STUDY_CONFIGURATION:
      return { ...study, ...action.payload };
    case StudyAction.SET_UTM_TAGS:
      return { ...study, ...action.payload };
    case StudyAction.STOP_COUNTDOWN:
      return { ...study, isCountdownRunning: false };
    case StudyAction.TICK_COUNTDOWN:
      return { ...study, timeLeft: action.payload };
    case StudyAction.TRIGGER_NASA_TLX:
      if (study.currentStep === StudyStep.ANALYSIS_TASK_1) {
        return {
          ...study,
          tlxQuestionnaireFirstTask: { ...study.tlxQuestionnaireFirstTask, isOpen: true }
        };
      } else if (study.currentStep === StudyStep.ANALYSIS_TASK_2) {
        return {
          ...study,
          tlxQuestionnaireSecondTask: { ...study.tlxQuestionnaireSecondTask, isOpen: true }
        };
      }
      break;
    case StudyAction.WITHDRAW:
      return {
        ...study,
        currentStep: StudyStep.GOOD_BYE,
        endedAt: new Date(),
        hasWithdrawn: true,
        withdrawalReason: action.payload
      };
    default:
      return study;
  }
};

export const useStudy = () => useContext(StudyContext);

export const useStudyDispatch = () => {
  const dispatch = useContext(StudyDispatchContext);

  return {
    nextStep: () => dispatch({ payload: undefined, type: StudyAction.NEXT_STEP }),
    resumeCountdown: () => dispatch({ payload: undefined, type: StudyAction.RESUME_COUNTDOWN }),
    setDemographicQuestionnaire: (demographicQuestionnaire: DemographicQuestionnaire) =>
      dispatch({
        payload: demographicQuestionnaire,
        type: StudyAction.ANSWER_DEMOGRAPHIC_QUESTIONNAIRE
      }),
    setInteractionQuestionnaire: (interactionQuestionnaire: InteractionQuestionnaire) =>
      dispatch({
        payload: interactionQuestionnaire,
        type: StudyAction.ANSWER_INTERACTION_QUESTIONNAIRE
      }),
    setSUSQuestionnaire: (susQuestionnaire: SUSQuestionnaire) =>
      dispatch({
        payload: susQuestionnaire,
        type: StudyAction.ANSWER_SUS_QUESTIONNAIRE
      }),
    setTLXQuestionnaire: (tlxQuestionnaire: TLXQuestionnaire, task: AnalysisTask) => {
      if (task === AnalysisTask.TASK_1) {
        dispatch({
          payload: tlxQuestionnaire,
          type: StudyAction.ANSWER_TLX_QUESTIONNAIRE_FIRST_TASK
        });
      } else if (task === AnalysisTask.TASK_2) {
        dispatch({
          payload: tlxQuestionnaire,
          type: StudyAction.ANSWER_TLX_QUESTIONNAIRE_SECOND_TASK
        });
      }
    },
    setPatterns: (patterns: Pattern[] | ((oldPatterns: Pattern[]) => Pattern[])) => {
      dispatch({ payload: patterns, type: StudyAction.CHANGE_PATTERNS });
    },
    startCountdown: () => dispatch({ payload: undefined, type: StudyAction.START_COUNTDOWN }),
    stopCountdown: () => dispatch({ payload: undefined, type: StudyAction.STOP_COUNTDOWN }),
    triggerNASATLX: () => dispatch({ payload: undefined, type: StudyAction.TRIGGER_NASA_TLX }),
    withdraw: (reason: string) => dispatch({ payload: reason, type: StudyAction.WITHDRAW })
  };
};
const StudyContext = createContext<StudyState>(defaultStudyState);
const StudyDispatchContext =
  createContext<({ payload, type }: { payload: any; type: StudyAction }) => void>(null);

const StudyContextProvider = ({ children }: { children: ReactNode }) => {
  const [storedStudyState, storeStudyState] = useLocalStorage('studyState', defaultStudyState, {
    deserializer: (studyStateAsString: string) => {
      const result = JSON.parse(studyStateAsString);

      replaceNullWithUndefined(result);

      return result;
    },
    raw: false,
    serializer: (studyState: StudyState) =>
      JSON.stringify(studyState, (key, value) => {
        if (value === undefined) {
          return null;
        } else if (['1', '2', '3', '4', '5'].contains(value)) {
          return parseInt(value, 10);
        }

        return value;
      })
  });
  const [study, dispatch] = useReducer(studyStateReducer, storedStudyState);
  const [timeLeft, { start, pause, resume }] = useCountDown(
    study.timeLeft > 0 && study.timeLeft < timeToCompleteTask ? study.timeLeft : timeToCompleteTask
  );

  useEffect(() => {
    // Record the UTM tags!
    const existingSearchParameters = new URLSearchParams(window.location.search);
    // If not existing, the UTM tags are `null` which is alright in our use case.
    const utmTags = {
      utmCampaign: existingSearchParameters.get('utm_campaign'),
      utmMedium: existingSearchParameters.get('utm_medium'),
      utmSource: existingSearchParameters.get('utm_source')
    };

    dispatch({ payload: utmTags, type: StudyAction.SET_UTM_TAGS });
  }, []);

  useAsync(async () => {
    // Do not reset the study configuration after re-load.
    if (
      study.firstMusicSheetCondition !== undefined &&
      study.firstMusicSheetID !== undefined &&
      study.secondMusicSheetCondition !== undefined &&
      study.secondMusicSheetID !== undefined
    )
      return;

    const studyConfiguration = await axios.get<{
      firstMusicSheetCondition: OperatorCondition;
      firstMusicSheetID: string;
      secondMusicSheetCondition: OperatorCondition;
      secondMusicSheetID: string;
    }>(`${getAPIURL()}/study/configuration`);

    dispatch({ payload: studyConfiguration.data, type: StudyAction.SET_STUDY_CONFIGURATION });
  }, []);

  useEffect(() => {
    /*
     * Add the participant ID to the URL for identification in Hotjar. Unfortunately,
     * Hotjar's Identify API is not available for the free plan.
     */
    if (study.id !== undefined) {
      const existingSearchParameters = new URLSearchParams(window.location.search);

      if (!existingSearchParameters.has('id')) {
        existingSearchParameters.append('id', study.id);

        window.history.replaceState(null, '', `?${existingSearchParameters.toString()}`);
      }
    }

    if (!isEqual(study, storedStudyState)) {
      storeStudyState(study);
      if (study.currentStep >= StudyStep.DEMOGRAPHIC_QUESTIONNAIRE) {
        // Only send the study state to the server if the user has started the study, i.e., accepted the terms.
        // noinspection JSIgnoredPromiseFromCall
        axios.put(`${getAPIURL()}/study/${study.id}`, study);
      }
    }
  }, [study]);

  useUpdateEffect(() => {
    dispatch({ payload: timeLeft, type: StudyAction.TICK_COUNTDOWN });

    if (timeLeft === 0 && study.isCountdownRunning)
      dispatch({ payload: undefined, type: StudyAction.TRIGGER_NASA_TLX });
  }, [timeLeft]);

  const modifiedDispatch = ({ payload, type }) => {
    if (type === StudyAction.TRIGGER_NASA_TLX) {
      if (
        study.currentStep === StudyStep.ANALYSIS_TASK_1 ||
        study.currentStep === StudyStep.ANALYSIS_TASK_2
      ) {
        pause();
      }
    } else if (type === StudyAction.RESUME_COUNTDOWN) {
      resume();
    } else if (type === StudyAction.START_COUNTDOWN) {
      start(
        study.timeLeft > 0 && study.timeLeft < timeToCompleteTask
          ? study.timeLeft
          : timeToCompleteTask
      );
    } else if (type === StudyAction.STOP_COUNTDOWN) {
      pause();
    }

    dispatch({ payload, type });
  };

  return (
    <StudyContext.Provider value={study}>
      <StudyDispatchContext.Provider value={modifiedDispatch}>
        {children}
      </StudyDispatchContext.Provider>
    </StudyContext.Provider>
  );
};

export default StudyContextProvider;

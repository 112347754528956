import axios from 'axios';
import { useState } from 'react';
import { useAsync } from 'react-use';
import { getAPIURL } from '../host';
import MusicSheet, { MusicSheetAsJSON } from '../models/MusicSheet';

const useMusicSheets = (): MusicSheet[] => {
  const [musicSheets, setMusicSheets] = useState<MusicSheet[]>([]);

  useAsync(async () => {
    if (musicSheets.length === 0) {
      const musicSheets = await axios.get<MusicSheetAsJSON[]>(`${getAPIURL()}/music-sheets`);

      const convertedMusicSheets = musicSheets.data.map(
        (musicSheetAsJSON) =>
          new MusicSheet(
            musicSheetAsJSON.id,
            musicSheetAsJSON.fileContents,
            musicSheetAsJSON.numberOfMeasures,
            musicSheetAsJSON.lastOffset,
            musicSheetAsJSON.title,
            musicSheetAsJSON.composer,
            musicSheetAsJSON.category
          )
      );

      setMusicSheets(convertedMusicSheets);
    }
  }, [musicSheets]);

  return musicSheets;
};

export default useMusicSheets;

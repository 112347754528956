import axios from 'axios';
import { useState } from 'react';
import { useAsync } from 'react-use';
import { getAPIURL } from '../host';
import { Voice } from '../models/Voice';

const useVoices = (musicSheetID: string | undefined): Voice[] => {
  const [voices, setVoices] = useState<Voice[]>([]);

  useAsync(async () => {
    if (musicSheetID) {
      const voices = await axios.get<Voice[]>(`${getAPIURL()}/voices/${musicSheetID}`);

      setVoices(voices.data);
    }
  }, [musicSheetID]);

  return voices;
};

export default useVoices;

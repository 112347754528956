import { createPortal } from 'react-dom';
import { lowerOpacity } from './constants';
import { areOccurrencesEqual, Occurrence, Pattern } from './models/Pattern';
import PatternTooltip from './PatternTooltip';

export const patternHighlightClass = 'pattern-highlight';

// Using a styled component would cause `SVGtoPDF` to render the ellipsis without the styles.
const EllipsisLine = ({
  color,
  colorOpacity,
  x1,
  x2,
  y1,
  y2
}: {
  color: string;
  colorOpacity: number;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}) => (
  <line
    stroke={color}
    strokeDasharray={5}
    strokeOpacity={colorOpacity}
    strokeWidth={4}
    x1={x1}
    x2={x2}
    y1={y1}
    y2={y2}
  />
);

const PatternHighlight = ({
  color,
  colorOpacity,
  coordinates,
  hasEllipsisLeft,
  hasEllipsisRight,
  hoveredOccurrenceIndex,
  hoveredOccurrences,
  hoveredPatternIDs,
  id,
  occurrenceIndex,
  page,
  pattern,
  shouldShowTooltip
}: {
  color: string;
  colorOpacity: number;
  coordinates: [number, number][];
  hasEllipsisLeft: boolean;
  hasEllipsisRight: boolean;
  hoveredOccurrenceIndex: number | undefined;
  hoveredOccurrences: Occurrence[];
  hoveredPatternIDs: string[];
  id: string;
  occurrenceIndex: number;
  page: SVGElement;
  pattern: Pattern;
  shouldShowTooltip: boolean;
}) => {
  if (coordinates.length === 1) coordinates.push(coordinates[0]);

  const firstCoordinate = coordinates[0];
  const isHovered = hoveredPatternIDs.includes(id) && hoveredOccurrenceIndex === occurrenceIndex;
  const lastCoordinate = coordinates[coordinates.length - 1];
  const shouldBeTransparent =
    (hoveredPatternIDs.length > 0 && !hoveredPatternIDs.includes(id)) ||
    (hoveredOccurrenceIndex !== undefined && occurrenceIndex !== hoveredOccurrenceIndex) ||
    (hoveredOccurrences.length > 0 &&
      !hoveredOccurrences.some((hoveredOccurrence) =>
        areOccurrencesEqual(pattern.occurrences[occurrenceIndex], hoveredOccurrence)
      ));

  if (shouldBeTransparent) colorOpacity = lowerOpacity;

  return createPortal(
    <PatternTooltip pattern={pattern} isOpen={shouldShowTooltip && isHovered && !hasEllipsisLeft}>
      <g className={patternHighlightClass}>
        {hasEllipsisLeft ? (
          <g>
            <EllipsisLine
              color={color}
              colorOpacity={colorOpacity}
              x1={firstCoordinate[0]}
              x2={firstCoordinate[0] - 25}
              y1={firstCoordinate[1] - 8}
              y2={firstCoordinate[1] - 8}
            />
            <EllipsisLine
              color={color}
              colorOpacity={colorOpacity}
              x1={firstCoordinate[0]}
              x2={firstCoordinate[0] - 25}
              y1={firstCoordinate[1] + 8}
              y2={firstCoordinate[1] + 8}
            />
          </g>
        ) : null}
        <polyline
          points={coordinates.map(([x, y]) => `${x},${y}`).join(' ')}
          style={{
            cursor: 'pointer',
            fill: 'none',
            stroke: color,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeOpacity: colorOpacity,
            strokeWidth: 20
          }}
        />
        {hasEllipsisRight ? (
          <g>
            <EllipsisLine
              color={color}
              colorOpacity={colorOpacity}
              x1={lastCoordinate[0]}
              x2={lastCoordinate[0] + 25}
              y1={lastCoordinate[1] - 8}
              y2={lastCoordinate[1] - 8}
            />
            <EllipsisLine
              color={color}
              colorOpacity={colorOpacity}
              x1={lastCoordinate[0]}
              x2={lastCoordinate[0] + 25}
              y1={lastCoordinate[1] + 8}
              y2={lastCoordinate[1] + 8}
            />
          </g>
        ) : null}
      </g>
    </PatternTooltip>,
    page
  );
};

export default PatternHighlight;

const isRunningInCI = Number(process.env.CI) === 1;

export const getAPIURL = (isWebsocket: boolean = false) => {
  let protocol = isWebsocket ? 'ws' : 'http';

  if (isRunningInCI) protocol += 's';

  return `${protocol}://${
    isRunningInCI ? 'api.study-melodic-patterns.musicvis.dbvis.de' : '0.0.0.0:5000'
  }/api`;
};

import { AccidentalEnum, GraphicalNote, Note, NoteEnum } from 'opensheetmusicdisplay';

export interface NoteContext {
  note: GraphicalNote;
  voiceNumber: number | undefined;
}

export const getAbsoluteOffset = (forNote: Note) => forNote.getAbsoluteTimestamp().RealValue * 4;

export const getChordSize = (forNote: Note) => forNote.ParentVoiceEntry.Notes.length;

export const getDuration = (forNote: Note) => forNote.Length.RealValue * 4;

export const getOffset = (forNote: Note) =>
  getAbsoluteOffset(forNote) - forNote.SourceMeasure.AbsoluteTimestamp.RealValue * 4;

export const getPitch = (forNote: Note) =>
  forNote.Pitch
    ? `${translateNoteEnumToString(forNote.Pitch.FundamentalNote)}${translateAccidentalEnumToString(
        forNote.Pitch.Accidental
      )}${forNote.Pitch.Octave + 3}`
    : undefined;

export const isChord = (note: Note) => note.ParentVoiceEntry.Notes.length > 1;

export const translateAccidentalEnumToString = (accidental: AccidentalEnum) => {
  switch (accidental) {
    case AccidentalEnum.SHARP:
      return '#';
    case AccidentalEnum.FLAT:
      return '-';
    default:
      return '';
  }
};

export const translateNoteEnumToString = (note: NoteEnum) => {
  switch (note) {
    case NoteEnum.A:
      return 'A';
    case NoteEnum.B:
      return 'B';
    case NoteEnum.C:
      return 'C';
    case NoteEnum.D:
      return 'D';
    case NoteEnum.E:
      return 'E';
    case NoteEnum.F:
      return 'F';
    case NoteEnum.G:
      return 'G';
  }
};

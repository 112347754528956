import { Button, Popover } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJoyride, { ACTIONS } from 'react-joyride';
import { musicSheetContainerID, musicSheetPaginationClass } from './MelodyAnalysis';
import { melodyGraphID } from './MelodyGraph';
import { melodicPatternConfigurationID } from './PatternConfiguration';
import { suggestionsID } from './Suggestions';
import { timelineID } from './Timeline';

const onBoardingSteps = [
  {
    content: 'onBoarding.step1.description',
    disableBeacon: true,
    placement: 'right',
    target: `#${musicSheetContainerID}`,
    title: 'onBoarding.step1.title'
  },
  {
    content: 'onBoarding.step2.description',
    disableBeacon: true,
    placement: 'bottom',
    target: musicSheetPaginationClass,
    title: 'onBoarding.step2.title'
  },
  {
    content: 'onBoarding.step3.description',
    disableBeacon: true,
    placement: 'bottom',
    target: `#${timelineID}`,
    title: 'onBoarding.step3.title'
  },
  {
    content: 'onBoarding.step4.description',
    disableBeacon: true,
    placement: 'left',
    target: `#${suggestionsID}`,
    title: 'onBoarding.step4.title'
  },
  {
    content: 'onBoarding.step5.description',
    disableBeacon: true,
    placement: 'left',
    target: `#${melodyGraphID}`,
    title: 'onBoarding.step5.title'
  },
  {
    content: 'onBoarding.step6.description',
    disableBeacon: true,
    placement: 'top',
    target: `#${melodicPatternConfigurationID}`,
    title: 'onBoarding.step6.title'
  }
];

const Tooltip = ({
  continuous,
  index,
  size,
  step,
  backProps,
  closeProps,
  primaryProps,
  skipProps,
  tooltipProps
}) => {
  const { ref, ...remainingTooltipProps } = tooltipProps;
  const { t: translate } = useTranslation();

  return (
    <div ref={ref}>
      <Popover
        {...remainingTooltipProps}
        content={
          <>
            {translate(step.content)}
            <div className="mt-4 flex justify-between">
              <Button {...skipProps} size="small" type="link">
                {translate('onBoarding.tooltip.skip')}
              </Button>
              <div>
                <Button {...backProps} className="mr-4" disabled={index === 0} size="small">
                  {translate('onBoarding.tooltip.back')}
                </Button>
                {continuous && index < size - 1 ? (
                  <Button {...primaryProps} size="small" type="primary">
                    {translate('onBoarding.tooltip.next')}
                  </Button>
                ) : (
                  <Button {...closeProps} size="small" type="primary">
                    {translate('onBoarding.tooltip.close')}
                  </Button>
                )}
              </div>
            </div>
          </>
        }
        open={true}
        overlayClassName="max-w-sm"
        placement={step.placement}
        title={translate(step.title)}
      ></Popover>
    </div>
  );
};

const OnBoarding = ({
  isVisible,
  setIsVisible
}: {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}) =>
  isVisible ? (
    <ReactJoyride
      callback={(event) =>
        (event.action === ACTIONS.CLOSE || event.action === ACTIONS.SKIP) && setIsVisible(false)
      }
      continuous
      run
      showSkipButton
      steps={onBoardingSteps}
      tooltipComponent={Tooltip}
    />
  ) : null;

export default OnBoarding;

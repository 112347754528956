import {
  AlignLeftOutlined,
  ColumnWidthOutlined,
  InfoCircleOutlined,
  TagOutlined
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Pattern } from './models/Pattern';

const PatternTooltip = ({
  children,
  isDeactivated = false,
  isOpen,
  pattern,
  shouldDisplayDisclaimer = false,
  shouldDisplayOccurrences = false
}: {
  children: ReactNode;
  isDeactivated?: boolean;
  isOpen?: boolean;
  pattern: Pattern;
  shouldDisplayDisclaimer?: boolean;
  shouldDisplayOccurrences?: boolean;
}) => {
  if (pattern === undefined || isDeactivated) return children;

  return (
    <Tooltip
      mouseEnterDelay={0}
      open={isOpen}
      placement="leftTop"
      title={() => {
        const { t: translate } = useTranslation();

        return (
          <>
            <div>
              <span className="mb-1 flex items-center text-slate-400 text-xs">
                <TagOutlined className="mr-1" />
                {translate('melodicPattern.configuration.label')}
              </span>
              <span className="ml-1">{pattern.label}</span>
            </div>
            <div className="mt-2">
              <span className="flex items-center text-slate-400 text-xs">
                <AlignLeftOutlined className="mr-1" />
                {translate('melodicPattern.configuration.description')}
              </span>
              <span className="ml-1">{pattern.description || '-'}</span>
            </div>
            <div className="mt-2">
              <span className="mb-1 flex items-center text-slate-400 text-xs">
                <ColumnWidthOutlined className="mr-1" />
                {translate('melodicPattern.configuration.length')}
              </span>
              <span className="ml-1">{pattern.length}</span>
            </div>
            {shouldDisplayOccurrences && (
              <div className="mt-2">
                <span className="mb-1 flex items-center text-slate-400 text-xs">
                  <ColumnWidthOutlined className="mr-1" />
                  {translate('melodicPattern.configuration.occurrences')}
                </span>
                <span className="ml-1">
                  {pattern.occurrences.length} ({pattern.uniqueOccurrencesCount})
                </span>
              </div>
            )}
            {shouldDisplayDisclaimer && (
              <span
                className="mt-2 flex items-center text-slate-400 leading-tight"
                style={{ fontSize: 'xx-small' }}
              >
                <InfoCircleOutlined className="mr-1" />
                {translate('melodicPattern.tooltip.disclaimer')}
              </span>
            )}
          </>
        );
      }}
    >
      {children}
    </Tooltip>
  );
};

export default PatternTooltip;

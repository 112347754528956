import { Layout, Result, Segmented, Space, Typography } from 'antd';
import { Selection } from 'd3';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import { useKey } from 'react-use';
import styled from 'styled-components';
import germanFlag from '../assets/images/languages/de.png';
import englishFlag from '../assets/images/languages/us.png';
import { NoteContext } from './models/Note';
import AnalysisTasks, { AnalysisTask } from './study-control-steps/AnalysisTasks';
import DemographicQuestionnaire from './study-control-steps/DemographicQuestionnaire';
import GoodBye from './study-control-steps/GoodBye';
import InteractionQuestionnaire from './study-control-steps/InteractionQuestionnaire';
import Introduction from './study-control-steps/Introduction';
import SUSQuestionnaire from './study-control-steps/SUSQuestionnaire';
import Welcome from './study-control-steps/Welcome';
import { useStudy } from './StudyContext';
import StudyControls, { StudyStep } from './StudyControls';

const { Text } = Typography;

export const isGoogleChrome = () => {
  // @ts-ignore
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  // @ts-ignore
  const isOpera = typeof window.opr !== 'undefined';
  const isIEEdge = winNav.userAgent.indexOf('Edg') > -1;
  const isIOSChrome = winNav.userAgent.match('CriOS');

  if (isIOSChrome) {
    return false;
  } else
    return (
      isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      isOpera === false &&
      isIEEdge === false
    );
};
export type NoteElementSelection = Selection<SVGGElement, NoteContext, null, undefined>;

const { Content, Footer } = Layout;

const AlignedSegmented = styled(Segmented)`
  .ant-segmented-item-label {
    align-items: center;
    display: flex;
  }
`;

const FullHeightLayout = styled(Layout)`
  height: 100%;
`;

const App = () => {
  const [hasEscapedMobileDeviceDisclaimer, setHasEscapedMobileDeviceDisclaimer] = useState(false);
  const study = useStudy();
  const { t: translate, i18n } = useTranslation();

  useKey(
    (event) => event.key === 'Escape',
    () => setHasEscapedMobileDeviceDisclaimer(true)
  );

  if (study === undefined) return null;

  const minutes = Math.floor(study.timeLeft / 1000 / 60);
  const seconds = study.timeLeft / 1000 - minutes * 60;
  const timeLeftText = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  if (isMobile && !hasEscapedMobileDeviceDisclaimer) {
    return (
      <Result
        extra={
          <Space className="!text-left" direction="vertical" size="middle">
            <Text className="text-lg" strong>
              {translate('study.mobileDeviceDisclaimer')}
            </Text>
            <Text className="text-lg" italic>
              <Trans components={[<Text keyboard />]} i18nKey="study.mobileDeviceEscape" />
            </Text>
          </Space>
        }
        status="warning"
        title={<b>{translate('study.unsupportedDevice')}</b>}
      />
    );
  }

  return (
    <FullHeightLayout>
      <StudyControls />
      <Content className="p-5 bg-white">
        {study.currentStep === StudyStep.WELCOME && !study.hasWithdrawn && <Welcome />}
        {study.currentStep === StudyStep.DEMOGRAPHIC_QUESTIONNAIRE && !study.hasWithdrawn && (
          <DemographicQuestionnaire />
        )}
        {study.currentStep === StudyStep.INTRODUCTION && !study.hasWithdrawn && <Introduction />}
        {study.currentStep === StudyStep.PLAYGROUND && !study.hasWithdrawn && (
          <AnalysisTasks task={AnalysisTask.PLAYGROUND} />
        )}
        {study.currentStep === StudyStep.ANALYSIS_TASK_1 && !study.hasWithdrawn && (
          <AnalysisTasks task={AnalysisTask.TASK_1} />
        )}
        {study.currentStep === StudyStep.ANALYSIS_TASK_2 && !study.hasWithdrawn && (
          <AnalysisTasks task={AnalysisTask.TASK_2} />
        )}
        {study.currentStep === StudyStep.SUS && !study.hasWithdrawn && <SUSQuestionnaire />}
        {study.currentStep === StudyStep.INTERACTION_QUESTIONNAIRE && !study.hasWithdrawn && (
          <InteractionQuestionnaire />
        )}
        {(study.hasWithdrawn || study.currentStep === StudyStep.GOOD_BYE) && <GoodBye />}
      </Content>
      <Footer
        className="!p-0 grid grid-cols-[25%_1fr_25%] !bg-white"
        style={{ fontSize: 'x-small', height: '40px' }}
      >
        <div></div>
        {minutes === 0 && seconds <= 30 ? (
          <span className="justify-self-center self-center text-2xl font-bold text-red-500">
            <b>
              {translate('footer.countdownLabel')}
              <u>{timeLeftText}</u>!
            </b>
          </span>
        ) : (
          <div></div>
        )}
        <div className="flex items-center justify-end">
          <AlignedSegmented
            onChange={(language) => i18n.changeLanguage(language as string)}
            options={[
              {
                icon: <img alt="The German flag" src={germanFlag} title="Deutsch" width={16} />,
                value: 'de'
              },
              {
                icon: (
                  <img
                    alt="The flag of the United States of America"
                    src={englishFlag}
                    title="English"
                    width={16}
                  />
                ),
                value: 'en'
              }
            ]}
            size="small"
            value={i18n.languages[0]}
          />
          <span className="ml-3 mr-5">&copy; {new Date().getFullYear()} Daniel Fürst</span>
        </div>
      </Footer>
    </FullHeightLayout>
  );
};

export default App;

import { Button, Modal, Radio, Space, Steps } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { timeToCompleteTask } from './constants';
import { useStudy, useStudyDispatch } from './StudyContext';

export enum StudyStep {
  WELCOME = 0,
  DEMOGRAPHIC_QUESTIONNAIRE = 1,
  INTRODUCTION = 2,
  PLAYGROUND = 3,
  ANALYSIS_TASK_1 = 4,
  ANALYSIS_TASK_2 = 5,
  SUS = 6,
  INTERACTION_QUESTIONNAIRE = 7,
  GOOD_BYE = 8
}

const { Step } = Steps;

const StudyControls = () => {
  const [likesToWithdraw, setLikesToWithdraw] = useState(false);
  const study = useStudy();
  const { nextStep, triggerNASATLX, withdraw } = useStudyDispatch();
  const { t: translate } = useTranslation();
  const [withdrawalReason, setWithdrawalReason] = useState(undefined);

  const minutes = Math.floor(study.timeLeft / 1000 / 60);
  const seconds = study.timeLeft / 1000 - minutes * 60;
  const timeLeftPercentage = (study.timeLeft / timeToCompleteTask) * 100;
  const timeLeftText = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  return (
    <>
      <div className="w-full h-[50px] flex justify-between bg-white p-4 pb-0">
        <Steps
          className="!w-[85%]"
          current={study.currentStep}
          percent={
            (study.currentStep === StudyStep.ANALYSIS_TASK_1 ||
              study.currentStep === StudyStep.ANALYSIS_TASK_2) &&
            (study.isCountdownRunning || (timeLeftPercentage > 0 && timeLeftPercentage < 100))
              ? timeLeftPercentage
              : undefined
          }
          size="small"
        >
          <Step title={translate('study.controls.welcome')} />
          <Step title={translate('study.controls.demographicQuestionnaire')} />
          <Step title={translate('study.controls.introduction')} />
          <Step title={translate('study.controls.playground')} />
          <Step
            subTitle={
              study.currentStep === StudyStep.ANALYSIS_TASK_1 &&
              (study.isCountdownRunning || (timeLeftPercentage > 0 && timeLeftPercentage < 100))
                ? timeLeftText
                : undefined
            }
            title={translate('study.controls.analysisTask1')}
          />
          <Step
            subTitle={
              study.currentStep === StudyStep.ANALYSIS_TASK_2 &&
              (study.isCountdownRunning || (timeLeftPercentage > 0 && timeLeftPercentage < 100))
                ? timeLeftText
                : undefined
            }
            title={translate('study.controls.analysisTask2')}
          />
          <Step title="SUS" />
          <Step title={translate('study.controls.interactionQuestionnaire')} />
        </Steps>
        <div>
          {study.currentStep >= StudyStep.PLAYGROUND &&
            study.currentStep <= StudyStep.ANALYSIS_TASK_2 && (
              <Button
                className="mr-1"
                onClick={() => {
                  if (study.currentStep === StudyStep.PLAYGROUND) {
                    nextStep();
                  } else {
                    triggerNASATLX();
                  }
                }}
                size="small"
                type="primary"
              >
                {translate('study.controls.continue')}
              </Button>
            )}
          <Button
            danger
            disabled={
              study.hasWithdrawn ||
              study.currentStep === StudyStep.WELCOME ||
              study.currentStep === StudyStep.GOOD_BYE
            }
            onClick={() => setLikesToWithdraw(true)}
            size="small"
            type="ghost"
          >
            {translate('study.controls.withdraw')}
          </Button>
        </div>
      </div>
      <Modal
        cancelText={translate('study.controls.withdrawModal.cancel')}
        centered
        closable={false}
        okText={translate('study.controls.withdrawModal.ok')}
        okType={'danger'}
        onCancel={() => setLikesToWithdraw(false)}
        onOk={() => {
          withdraw(withdrawalReason);
          setLikesToWithdraw(false);
        }}
        open={likesToWithdraw}
        title={translate('study.controls.withdrawModal.title')}
      >
        <p>{translate('study.controls.withdrawModal.explanation')}</p>
        <p>{translate('study.controls.withdrawModal.reason.label')}</p>
        <Radio.Group
          className="!ml-2"
          onChange={(event) => setWithdrawalReason(event.target.value)}
          size="small"
        >
          <Space direction="vertical">
            <Radio value="melodic operators">
              {translate('study.controls.withdrawModal.reason.options.melodicOperators')}
            </Radio>
            <Radio value="unclear instructions">
              {translate('study.controls.withdrawModal.reason.options.unclearInstructions')}
            </Radio>
            <Radio value="too difficult">
              {translate('study.controls.withdrawModal.reason.options.tooDifficult')}
            </Radio>
            <Radio value="too long">
              {translate('study.controls.withdrawModal.reason.options.tooLong')}
            </Radio>
            <Radio value="not interested">
              {translate('study.controls.withdrawModal.reason.options.notInterested')}
            </Radio>
            <Radio value="technical issues">
              {translate('study.controls.withdrawModal.reason.options.technicalIssues')}
            </Radio>
          </Space>
        </Radio.Group>
      </Modal>
    </>
  );
};

export default StudyControls;

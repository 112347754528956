import axios from 'axios';
import { useState } from 'react';
import { useAsync } from 'react-use';
import { getAPIURL } from '../host';
import MusicSheet, { MusicSheetAsJSON } from '../models/MusicSheet';

const useMusicSheet = (musicSheetID: string | undefined): MusicSheet => {
  const [musicSheet, setMusicSheet] = useState<MusicSheet>();

  useAsync(async () => {
    if (!musicSheetID) return;

    const musicSheet = await axios.get<MusicSheetAsJSON>(
      `${getAPIURL()}/music-sheets/${musicSheetID}`
    );

    const convertedMusicSheet = new MusicSheet(
      musicSheet.data.id,
      musicSheet.data.fileContents,
      musicSheet.data.numberOfMeasures,
      musicSheet.data.lastOffset,
      musicSheet.data.title,
      musicSheet.data.composer,
      musicSheet.data.category
    );

    setMusicSheet(convertedMusicSheet);
  }, [musicSheetID]);

  return musicSheet;
};

export default useMusicSheet;

import {
  AlignLeftOutlined,
  BgColorsOutlined,
  ColumnWidthOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  LockOutlined,
  NumberOutlined,
  TagOutlined,
  UnlockOutlined
} from '@ant-design/icons';
import { Button, Input, Popover, Skeleton, Tooltip, Typography } from 'antd';
import { debounce } from 'lodash';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { CustomPicker } from 'react-color';
import { Hue } from 'react-color/lib/components/common';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Node } from './MelodyGraph';
import { Pattern, PatternLabelGenerator } from './models/Pattern';
import { AlignedSwitch } from './MusicSheetSelection';

export const melodicPatternConfigurationID = 'melodic-pattern-configuration';

const ColoredPatch = styled.div`
  background-color: ${(styles) => styles.color};
  border-radius: 0.2rem;
  height: 15px;
  width: 100px;
`;

const HueWrapper = styled.div`
  width: -fill-available;

  div > .hue-horizontal > div > div {
    height: 22px !important;
  }
`;

const SmallInput = styled(Input)`
  width: 100px;
`;

const SmallSkeletonInput = styled(Skeleton.Input)`
  height: 22px !important;
  width: ${(styles) => (styles.stretch ? '-webkit-fill-available' : '100px')};

  .ant-skeleton-input {
    min-width: unset;
    width: ${(styles) => (styles.stretch ? '-webkit-fill-available' : '100px')};
  }
`;

const { Text, Title } = Typography;

const ColorPicker = CustomPicker((props) => {
  const [intermediateColor, setIntermediateColor] = useState<string>(props.hex);

  return (
    <div className="grid grid-cols-2 gap-x-1">
      <HueWrapper className="relative h-[24px] p-1">
        <Hue {...props} onChange={props.onChange} />
      </HueWrapper>
      <Input
        maxLength={7}
        onBlur={() => setIntermediateColor(props.hex)}
        onChange={(event) => {
          const color = event.target.value;

          setIntermediateColor(color);
          if (/^#[0-9A-F]{6}$/i.test(color)) props.onChange(color);
        }}
        prefix={<BgColorsOutlined />}
        size="small"
        value={intermediateColor}
      />
    </div>
  );
});

const PatternConfiguration = ({
  configuringID,
  nodes,
  patterns,
  setConfiguringID,
  setNodes,
  setPatterns,
  setSuggestingID
}: {
  configuringID: string;
  nodes: Node[];
  patterns: Pattern[];
  setConfiguringID: Dispatch<SetStateAction<string>>;
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setPatterns: Dispatch<SetStateAction<Pattern[]>>;
  setSuggestingID: Dispatch<SetStateAction<string>>;
}) => {
  const pattern = patterns.find((pattern) => pattern.id === configuringID);

  const [color, setColor] = useState(pattern?.color);
  const [description, setDescription] = useState(pattern?.description);
  const [label, setLabel] = useState(pattern?.label);
  const { t: translate } = useTranslation();

  const onChangeDescription = useMemo(
    () =>
      debounce((newDescription: string) => {
        setPatterns((oldPatterns) => {
          const patternToChange = oldPatterns.find((pattern) => pattern.id === configuringID);

          if (patternToChange !== undefined) {
            patternToChange.description = newDescription;

            return [...oldPatterns];
          } else {
            return oldPatterns;
          }
        });
      }, 750),
    [configuringID]
  );
  const onChangeLabel = useMemo(
    () =>
      debounce((newLabel: string) => {
        setPatterns((oldPatterns) => {
          const patternToChange = oldPatterns.find((pattern) => pattern.id === configuringID);

          if (patternToChange !== undefined) {
            patternToChange.label = newLabel;

            return [...oldPatterns];
          } else {
            return oldPatterns;
          }
        });
      }, 750),
    [configuringID]
  );

  return (
    <div className="flex items-center justify-between" id={melodicPatternConfigurationID}>
      <Title
        className="!m-0 text-center rotate-180 whitespace-pre-wrap !leading-none"
        level={5}
        style={{ writingMode: 'vertical-rl' }}
      >
        {translate('melodicPattern.configuration.title')}
      </Title>
      <div
        className="pb-4 pr-2 pt-4 grid gap-x-4 gap-y-2"
        style={{
          gridTemplateColumns: 'repeat(4, min-content(1px))'
        }}
      >
        <div>
          <span className="mb-1 flex items-center text-slate-400 text-xs">
            <TagOutlined className="mr-1" />
            {translate('melodicPattern.configuration.label')}
          </span>
          {configuringID === undefined ? (
            <SmallSkeletonInput size="small" />
          ) : (
            <SmallInput
              bordered={false}
              maxLength={50}
              onChange={(event) => {
                setLabel(event.target.value);
                onChangeLabel(event.target.value);
              }}
              size="small"
              value={label || pattern?.label}
            />
          )}
        </div>
        <div>
          <span className="mb-1 flex items-center text-slate-400 text-xs">
            <BgColorsOutlined className="mr-1" />
            {translate('melodicPattern.configuration.color')}
            <Tooltip
              overlayClassName="!text-xs"
              title={translate('melodicPattern.configuration.disclaimer')}
            >
              <InfoCircleOutlined className="ml-1" style={{ fontSize: 'xx-small' }} />
            </Tooltip>
          </span>
          {configuringID === undefined ? (
            <SmallSkeletonInput size="small" />
          ) : (
            <Popover
              content={
                <ColorPicker
                  color={color || pattern?.color}
                  onChange={(color) => setColor(color.hex)}
                  onChangeComplete={(color) =>
                    setPatterns((oldPatterns) => {
                      const patternToChange = oldPatterns.find(
                        (pattern) => pattern.id === configuringID
                      );

                      if (patternToChange !== undefined) {
                        patternToChange.color = color.hex;

                        setColor(color.hex);

                        return [...oldPatterns];
                      } else {
                        return oldPatterns;
                      }
                    })
                  }
                />
              }
              id="color-picker"
              overlayInnerStyle={{ padding: '0.5rem', width: 250 }}
              trigger="click"
            >
              <ColoredPatch className="ml-1 mt-2" color={pattern?.color} />
            </Popover>
          )}
        </div>
        <div>
          <span className="mb-1 flex items-center text-slate-400 text-xs">
            <ColumnWidthOutlined className="mr-1" />
            {translate('melodicPattern.configuration.length')}
          </span>
          {configuringID === undefined ? (
            <SmallSkeletonInput size="small" />
          ) : (
            <Text className="ml-1.5">{pattern?.length}</Text>
          )}
        </div>
        <div>
          <span className="mb-1 flex items-center text-slate-400 text-xs whitespace-nowrap">
            <NumberOutlined className="mr-1" />
            {translate('melodicPattern.configuration.occurrences')}
          </span>
          {configuringID === undefined ? (
            <SmallSkeletonInput size="small" stretch />
          ) : (
            <Text className="ml-1.5">
              {pattern?.occurrences.length} ({pattern?.uniqueOccurrencesCount})
            </Text>
          )}
        </div>
        <div style={{ gridColumn: 'span 4' }}>
          <span className="mb-1 flex items-center text-slate-400 text-xs">
            <AlignLeftOutlined className="mr-1" />
            {translate('melodicPattern.configuration.description')}
          </span>
          {configuringID === undefined ? (
            <SmallSkeletonInput size="small" stretch />
          ) : (
            <Input
              bordered={false}
              maxLength={280}
              onChange={(event) => {
                setDescription(event.target.value);
                onChangeDescription(event.target.value);
              }}
              placeholder={translate('melodicPattern.configuration.descriptionPlaceholder')}
              size="small"
              value={description || pattern?.description}
            />
          )}
        </div>
      </div>
      <div className="h-full flex flex-col items-center justify-center">
        <AlignedSwitch
          checked={pattern?.isLocked}
          checkedChildren={<LockOutlined />}
          disabled={configuringID === undefined}
          onChange={(checked) => {
            const nodeIndexToUnlock = nodes.findIndex((node) => node.id === configuringID);

            if (checked) {
              (nodes[nodeIndexToUnlock] as Node).fx = nodes[nodeIndexToUnlock].x;
              (nodes[nodeIndexToUnlock] as Node).fy = nodes[nodeIndexToUnlock].y;
            } else {
              delete (nodes[nodeIndexToUnlock] as Node).fx;
              delete (nodes[nodeIndexToUnlock] as Node).fy;
            }

            setNodes([...nodes]);
            setPatterns((oldPatterns) => {
              const patternToChange = oldPatterns.find(
                (oldPattern) => oldPattern.id === configuringID
              );

              patternToChange.isLocked = checked;

              return [...oldPatterns];
            });
          }}
          size="small"
          unCheckedChildren={<UnlockOutlined />}
        />
        <Button
          className="mt-2"
          danger
          disabled={configuringID === undefined || !pattern.isUserDefined}
          icon={<DeleteOutlined />}
          onClick={() => {
            setPatterns((oldPatterns) => {
              const patternToDelete = oldPatterns.find(
                (oldPattern) => oldPattern.id === configuringID
              );
              const patternToDeleteIndex = oldPatterns.findIndex(
                (oldPattern) => oldPattern.id === configuringID
              );

              if (patternToDeleteIndex !== -1) {
                if (patternToDelete && patternToDelete.isUserDefined) {
                  patternToDelete.connectedToByOperator.forEach((target) => {
                    const targetPatternIndex = oldPatterns.findIndex(
                      (oldPattern) => oldPattern.id === target.id
                    );

                    if (targetPatternIndex !== -1) oldPatterns.splice(targetPatternIndex, 1);
                  });
                }

                oldPatterns.splice(patternToDeleteIndex, 1);
                PatternLabelGenerator.add(patternToDelete.label);
              }

              return [...oldPatterns];
            });
            setConfiguringID(undefined);
            setSuggestingID(undefined);
          }}
          size="small"
          type="text"
        ></Button>
      </div>
    </div>
  );
};

export default PatternConfiguration;

import { Button, Checkbox, Typography } from 'antd';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import styled from 'styled-components';
import { isGoogleChrome } from '../App';
import { minimumHeight, minimumWidth } from '../constants';
import { useStudyDispatch } from '../StudyContext';

const { Paragraph, Text, Title } = Typography;

const VerticalCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
`;

const MacOSKeyShortcut = () => (
  <Text className="text-gray-400" style={{ fontSize: 'smaller' }}>
    <Text keyboard>⌘ command</Text>+<Text keyboard>-</Text>
  </Text>
);
const WindowsKeyShortcut = () => (
  <Text className="text-gray-400" style={{ fontSize: 'smaller' }}>
    <Text keyboard>Strg</Text>+<Text keyboard>-</Text>
  </Text>
);

const Welcome = () => {
  const [hasAcceptedRequirements, setHasAcceptedRequirements] = useState(false);
  const [hasGivenConsent, setHasGivenConsent] = useState(false);
  const { height, width } = useWindowSize();
  const { nextStep } = useStudyDispatch();
  const { t: translate } = useTranslation();

  const isScreenTooSmall = height < minimumHeight || width < minimumWidth;

  return (
    <div className="h-full w-full flex flex-col justify-center">
      <div className="mx-auto w-1/2">
        <Title className="mt-8">👋🏼 {translate('study.welcome.title')}</Title>
        <Paragraph>
          <Trans
            components={[<a href="https://uni.kn/" target="_blank" />]}
            i18nKey="study.welcome.opening"
          ></Trans>
        </Paragraph>
        <Paragraph>
          {translate('study.welcome.procedure.label')}
          <ol className="!mt-1">
            <li>{translate('study.welcome.procedure.steps.1')}</li>
            <li>{translate('study.welcome.procedure.steps.2')}</li>
            <li>{translate('study.welcome.procedure.steps.3')}</li>
            <li>{translate('study.welcome.procedure.steps.4')}</li>
            <li>{translate('study.welcome.procedure.steps.5')}</li>
          </ol>
        </Paragraph>
        <Paragraph>
          <Trans components={[<i />]} i18nKey="study.welcome.dataPrivacy" />
        </Paragraph>
        <Paragraph>
          {translate('study.welcome.requirements.opening')}
          <VerticalCheckboxGroup
            className="!mb-3 !ml-1 !mt-1.5"
            onChange={(values) => setHasAcceptedRequirements(values.length === 4)}
            options={[
              { label: translate('study.welcome.requirements.first'), value: 'first' },
              {
                disabled: isScreenTooSmall,
                label: (
                  <>
                    <Trans
                      components={[<i />, <b />]}
                      i18nKey="study.welcome.requirements.second"
                      values={{ height: minimumHeight, width: minimumWidth }}
                    />
                    {isScreenTooSmall ? (
                      <>
                        {' '}
                        <span className="!font-bold !text-red-500">
                          <Trans
                            components={[<WindowsKeyShortcut />, <MacOSKeyShortcut />]}
                            i18nKey="study.welcome.requirements.secondHint"
                            values={{ height, width }}
                          />
                        </span>
                      </>
                    ) : null}
                  </>
                ),
                value: 'second'
              },
              { label: translate('study.welcome.requirements.third'), value: 'third' },
              {
                disabled: !isGoogleChrome(),
                label: (
                  <>
                    <Trans components={[<b />]} i18nKey={'study.welcome.requirements.fourth'} />
                    {!isGoogleChrome() ? (
                      <>
                        {' '}
                        <span className="!font-bold !text-red-500">
                          <Trans
                            components={[
                              <a
                                href="https://support.google.com/chrome/answer/95346"
                                target="_blank"
                              />
                            ]}
                            i18nKey={'study.welcome.requirements.fourthHint'}
                          />
                        </span>
                      </>
                    ) : null}
                  </>
                ),
                value: 'fourth'
              }
            ]}
          />
          <Trans
            components={[<a href="mailto:daniel.fuerst@uni.kn" />]}
            i18nKey="study.welcome.technicalIssues"
          ></Trans>
        </Paragraph>
        <Paragraph className="mt-6">
          <Checkbox onChange={(event) => setHasGivenConsent(event.target.checked)}>
            {translate('study.welcome.consent')}
          </Checkbox>
        </Paragraph>
        <Button
          className="float-right"
          disabled={!hasGivenConsent || !hasAcceptedRequirements}
          onClick={nextStep}
          type="primary"
        >
          {translate('study.welcome.button')}
        </Button>
      </div>
    </div>
  );
};

export default Welcome;

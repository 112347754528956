/**
 * Represent a music sheet as JSON.
 */
export interface MusicSheetAsJSON {
  category: string;
  composer: string;
  id: string;
  fileContents: string;
  lastOffset: number;
  numberOfMeasures: number;
  title: string;
}

export default class MusicSheet {
  /**
   * Represent a music sheet.
   *
   * @param id The ID of the music sheet in the database.
   * @param musicXML The music sheet in [MusicXML](https://www.musicxml.com/).
   * @param numberOfMeasures The number of measures that this music sheet comprises.
   * @param lastOffset The offset of the last note in the music sheet.
   * @param title The title of the music sheet.
   * @param composer The composer of the music sheet.
   * @param category The category of the music sheet.
   */
  constructor(
    public id: string,
    public musicXML: string,
    public numberOfMeasures: number,
    public lastOffset: number,
    public title: string,
    public composer: string,
    public category: string
  ) {}
}

import { Button, Form, Input, Radio, Typography } from 'antd';
import { debounce } from 'lodash';
import { ReactNode, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import melodicPatternHighlighting from '../../assets/images/study/melodicPatternHighlighting.png';
import melodyGraph from '../../assets/images/study/melodyGraph.png';
import melodyTimelineDE from '../../assets/images/study/melodyTimelineDE.png';
import melodyTimelineEN from '../../assets/images/study/melodyTimelineEN.png';
import { defaultDeviationPercentage } from '../constants';
import { getIconForOperator, Operator } from '../models/Pattern';
import {
  InteractionQuestionnaire as InteractionQuestionnaireType,
  useStudy,
  useStudyDispatch
} from '../StudyContext';

const { Paragraph, Text, Title } = Typography;

const InteractionQuestionnaire = () => {
  const study = useStudy();
  const [areAllRequiredFieldsFilled, setAreAllRequiredFieldsFilled] = useState(
    Object.entries(study.interactionQuestionnaire || {}).every(
      ([key, value]) => value !== undefined || key === 'contact'
    )
  );
  const { nextStep, setInteractionQuestionnaire } = useStudyDispatch();
  const { t: translate, i18n } = useTranslation();

  const onFieldsChange = debounce((changedFields, allFields) => {
    onFieldsChange.cancel();

    if (
      allFields
        .filter(
          (field) =>
            ![
              'dislikedAboutPrototype',
              'likedAboutPrototype',
              'magicWandPrototype',
              'prototypeApplications',
              'prototypeUsefulness',
              'reasonsForPatterns'
            ].includes(field.name[0])
        )
        .every((field) => field.touched)
    )
      setAreAllRequiredFieldsFilled(true);

    setInteractionQuestionnaire(
      allFields.reduce((result, field) => {
        result[field.name[0]] = field.value;

        return result;
      }, {} as InteractionQuestionnaireType)
    );
  }, 750);

  return (
    <div className="h-full w-full flex flex-col overflow-y-auto">
      <div className="mx-auto w-1/2">
        <Title className="!mt-8">📝 {translate('study.interactionQuestionnaire.title')}</Title>
        <Paragraph className="!mb-12">
          {translate('study.interactionQuestionnaire.opening')}
        </Paragraph>
        <Form
          fields={Object.entries(study.interactionQuestionnaire || {}).map(([name, value]) => ({
            name: [name],
            touched: value !== undefined,
            value
          }))}
          layout="vertical"
          onFieldsChange={onFieldsChange}
          onFinish={(values) => {
            setInteractionQuestionnaire(values);
            nextStep();
          }}
        >
          <Title className="!mb-8" level={2}>
            {translate('study.interactionQuestionnaire.general')}
          </Title>
          <LikertScale
            lowerEndLabel={translate('study.interactionQuestionnaire.veryUseful')}
            name="prototypeUsefulness"
            question={translate('study.interactionQuestionnaire.prototypeUsefulness')}
            required={false}
            upperEndLabel={translate('study.interactionQuestionnaire.veryUseless')}
          />
          <LikertScale
            lowerEndLabel={translate('study.interactionQuestionnaire.veryLittle')}
            name="prototypeEffort"
            question={translate('study.interactionQuestionnaire.prototypeEffort')}
            upperEndLabel={translate('study.interactionQuestionnaire.veryMuch')}
          />
          <LikertScale
            lowerEndLabel={translate('study.interactionQuestionnaire.veryEasy')}
            name="prototypeUsability"
            question={translate('study.interactionQuestionnaire.prototypeUsability')}
            upperEndLabel={translate('study.interactionQuestionnaire.veryHard')}
          />
          <Title className="!mb-8 mt-16" level={2}>
            {translate('study.interactionQuestionnaire.melodicPatternHighlighting')}
          </Title>
          <figure className="mb-8">
            <img className="block mx-auto" src={melodicPatternHighlighting} width="50%" />
            <figcaption className="mt-2 text-center text-gray-500 text-xs">
              <i>{translate('study.interactionQuestionnaire.patternHighlightingImageCaption')}</i>
            </figcaption>
          </figure>
          <LikertScale
            lowerEndLabel={translate('study.interactionQuestionnaire.veryUseful')}
            name="patternHighlightingUsefulness"
            question={translate('study.interactionQuestionnaire.patternHighlightingUsefulness')}
            upperEndLabel={translate('study.interactionQuestionnaire.veryUseless')}
          />
          <LikertScale
            lowerEndLabel={translate('study.interactionQuestionnaire.veryEasy')}
            name="patternHighlightingUsability"
            question={translate('study.interactionQuestionnaire.patternHighlightingUsability')}
            upperEndLabel={translate('study.interactionQuestionnaire.veryHard')}
          />
          <Title className="!mb-8 mt-16" level={2}>
            {translate('study.interactionQuestionnaire.melodicOperators')}
          </Title>
          <LikertScale
            lowerEndLabel={translate('study.interactionQuestionnaire.veryUseful')}
            name="melodicOperatorsUsefulness"
            question={translate('study.interactionQuestionnaire.melodicOperatorsUsefulness')}
            upperEndLabel={translate('study.interactionQuestionnaire.veryUseless')}
          />
          <LikertScale
            lowerEndLabel={translate('study.interactionQuestionnaire.veryEasy')}
            name="melodicOperatorsUsability"
            question={translate('study.interactionQuestionnaire.melodicOperatorsUsability')}
            upperEndLabel={translate('study.interactionQuestionnaire.veryHard')}
          />
          <LikertScale
            help={<MelodicOperatorHelp operator={Operator.TRANSPOSITION} />}
            lowerEndLabel={translate('study.interactionQuestionnaire.veryImportant')}
            name="melodicOperatorsTransposition"
            question={<MelodicOperatorQuestion operator={Operator.TRANSPOSITION} />}
            upperEndLabel={translate('study.interactionQuestionnaire.veryUnimportant')}
          />
          <LikertScale
            help={<MelodicOperatorHelp operator={Operator.MIRROR_X} />}
            lowerEndLabel={translate('study.interactionQuestionnaire.veryImportant')}
            name="melodicOperatorsMirrorX"
            question={<MelodicOperatorQuestion operator={Operator.MIRROR_X} />}
            upperEndLabel={translate('study.interactionQuestionnaire.veryUnimportant')}
          />
          <LikertScale
            help={<MelodicOperatorHelp operator={Operator.MIRROR_Y} />}
            lowerEndLabel={translate('study.interactionQuestionnaire.veryImportant')}
            name="melodicOperatorsMirrorY"
            question={<MelodicOperatorQuestion operator={Operator.MIRROR_Y} />}
            upperEndLabel={translate('study.interactionQuestionnaire.veryUnimportant')}
          />
          <LikertScale
            help={<MelodicOperatorHelp operator={Operator.DIMINUTION} />}
            lowerEndLabel={translate('study.interactionQuestionnaire.veryImportant')}
            name="melodicOperatorsDiminution"
            question={<MelodicOperatorQuestion operator={Operator.DIMINUTION} />}
            upperEndLabel={translate('study.interactionQuestionnaire.veryUnimportant')}
          />
          <LikertScale
            help={<MelodicOperatorHelp operator={Operator.AUGMENTATION} />}
            lowerEndLabel={translate('study.interactionQuestionnaire.veryImportant')}
            name="melodicOperatorsAugmentation"
            question={<MelodicOperatorQuestion operator={Operator.AUGMENTATION} />}
            upperEndLabel={translate('study.interactionQuestionnaire.veryUnimportant')}
          />
          <LikertScale
            help={<MelodicOperatorHelp operator={Operator.REDUCTION_TO_PITCH} />}
            lowerEndLabel={translate('study.interactionQuestionnaire.veryImportant')}
            name="melodicOperatorsReductionToPitch"
            question={<MelodicOperatorQuestion operator={Operator.REDUCTION_TO_PITCH} />}
            upperEndLabel={translate('study.interactionQuestionnaire.veryUnimportant')}
          />
          <LikertScale
            help={<MelodicOperatorHelp operator={Operator.REDUCTION_TO_RHYTHM} />}
            lowerEndLabel={translate('study.interactionQuestionnaire.veryImportant')}
            name="melodicOperatorsReductionToRhythm"
            question={<MelodicOperatorQuestion operator={Operator.REDUCTION_TO_RHYTHM} />}
            upperEndLabel={translate('study.interactionQuestionnaire.veryUnimportant')}
          />
          <LikertScale
            help={<MelodicOperatorHelp operator={Operator.DEVIATION} />}
            lowerEndLabel={translate('study.interactionQuestionnaire.veryImportant')}
            name="melodicOperatorsDeviation"
            question={<MelodicOperatorQuestion operator={Operator.DEVIATION} />}
            upperEndLabel={translate('study.interactionQuestionnaire.veryUnimportant')}
          />
          <LikertScale
            lowerEndLabel={translate('study.interactionQuestionnaire.veryTrustful')}
            name="melodicOperatorsTrust"
            question={translate('study.interactionQuestionnaire.melodicOperatorsTrust')}
            upperEndLabel={translate('study.interactionQuestionnaire.veryDistrustful')}
          />
          <Title className="!mb-8 mt-16" level={2}>
            {translate('study.interactionQuestionnaire.melodyGraph')}
          </Title>
          <figure className="mb-8">
            <img className="block mx-auto" src={melodyGraph} width={150} />
            <figcaption className="mt-2 text-center text-gray-500 text-xs">
              <i>{translate('study.interactionQuestionnaire.melodyGraphImageCaption')}</i>
            </figcaption>
          </figure>
          <LikertScale
            lowerEndLabel={translate('study.interactionQuestionnaire.veryUseful')}
            name="melodyGraphUsefulness"
            question={translate('study.interactionQuestionnaire.melodyGraphUsefulness')}
            upperEndLabel={translate('study.interactionQuestionnaire.veryUseless')}
          />
          <LikertScale
            lowerEndLabel={translate('study.interactionQuestionnaire.veryEasy')}
            name="melodyGraphUsability"
            question={translate('study.interactionQuestionnaire.melodyGraphUsability')}
            upperEndLabel={translate('study.interactionQuestionnaire.veryHard')}
          />
          <Title className="!mb-8 mt-16" level={2}>
            {translate('study.interactionQuestionnaire.melodyTimeline')}
          </Title>
          <figure className="mb-8">
            <img
              className="block mx-auto"
              src={i18n.language === 'de' ? melodyTimelineDE : melodyTimelineEN}
              width="75%"
            />
            <figcaption className="mt-2 text-center text-gray-500 text-xs">
              <i>{translate('study.interactionQuestionnaire.melodyTimelineImageCaption')}</i>
            </figcaption>
          </figure>
          <LikertScale
            lowerEndLabel={translate('study.interactionQuestionnaire.veryUseful')}
            name="melodyTimelineUsefulness"
            question={translate('study.interactionQuestionnaire.melodyTimelineUsefulness')}
            upperEndLabel={translate('study.interactionQuestionnaire.veryUseless')}
          />
          <LikertScale
            lowerEndLabel={translate('study.interactionQuestionnaire.veryEasy')}
            name="melodyTimelineUsability"
            question={translate('study.interactionQuestionnaire.melodyTimelineUsability')}
            upperEndLabel={translate('study.interactionQuestionnaire.veryHard')}
          />
          <Title className="!mb-8 mt-16" level={2}>
            {translate('study.interactionQuestionnaire.feedback')}
          </Title>
          <OpenQuestion
            name="reasonsForPatterns"
            question={translate('study.interactionQuestionnaire.reasonsForPatterns')}
          />
          <OpenQuestion
            name="likedAboutPrototype"
            question={
              <Trans
                components={[<u />]}
                i18nKey="study.interactionQuestionnaire.likedAboutPrototype"
              ></Trans>
            }
          />
          <OpenQuestion
            name="dislikedAboutPrototype"
            question={
              <Trans
                components={[<u />]}
                i18nKey="study.interactionQuestionnaire.dislikedAboutPrototype"
              ></Trans>
            }
          />
          <OpenQuestion
            name="magicWandPrototype"
            question={translate('study.interactionQuestionnaire.magicWandPrototype')}
          />
          <OpenQuestion
            name="prototypeApplications"
            question={translate('study.interactionQuestionnaire.prototypeApplications')}
          />
          <Form.Item>
            <Button
              className="float-right"
              disabled={!areAllRequiredFieldsFilled}
              htmlType="submit"
              type="primary"
            >
              {translate('study.interactionQuestionnaire.button')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export const LikertScale = ({
  help,
  lowerEndLabel,
  name,
  question,
  required = true,
  upperEndLabel,
  withOffset = false
}: {
  help?: ReactNode;
  lowerEndLabel: string;
  name: string;
  question: ReactNode;
  required?: boolean;
  upperEndLabel: string;
  withOffset?: boolean;
}) => {
  return (
    <Form.Item
      help={help}
      label={<Title level={4}>{question}</Title>}
      name={name}
      required={required}
      wrapperCol={{ offset: withOffset ? 1 : 0 }}
    >
      <ValueWrapper lowerEndLabel={lowerEndLabel} upperEndLabel={upperEndLabel} />
    </Form.Item>
  );
};

const MelodicOperatorHelp = ({ operator }: { operator: Operator }) => {
  const { t: translate } = useTranslation();

  if (operator === Operator.DEVIATION) {
    return (
      <div className="mb-8 ml-6 mt-3" style={{ maxWidth: '75%' }}>
        <i>{translate('study.interactionQuestionnaire.melodicOperatorDescriptionLabel')}</i>{' '}
        <Trans
          components={[<span />]}
          i18nKey={`suggestions.operators.${operator.toLowerCase()}.description`}
          values={{ deviationPercentage: defaultDeviationPercentage }}
        />
      </div>
    );
  } else {
    return (
      <div className="mb-6 ml-6 mt-3" style={{ maxWidth: '75%' }}>
        <i>{translate('study.interactionQuestionnaire.melodicOperatorDescriptionLabel')}</i>{' '}
        {translate(`suggestions.operators.${operator.toLowerCase()}.description`)}
      </div>
    );
  }
};

const MelodicOperatorQuestion = ({ operator }: { operator: Operator }) => {
  const { t: translate } = useTranslation();

  return (
    <Trans
      components={[
        <img
          alt={operator}
          className="mb-1 ml-1 mr-0.5 p-0.5 border border-solid border-gray-300"
          height={20}
          src={getIconForOperator(operator)}
          width={20}
        />,
        <span className="italic underline" />
      ]}
      i18nKey="study.interactionQuestionnaire.melodicOperatorImportance"
      values={{ operator: translate(`suggestions.operators.${operator.toLowerCase()}.title`) }}
    />
  );
};

/*
 * This wrapper is necessary to enable customized components in `Form` through an event listener,
 * i.e., `onChange`, and a way to pass the value to the `Radio.Group`.
 */
const ValueWrapper = ({
  lowerEndLabel,
  onChange,
  upperEndLabel,
  value
}: {
  lowerEndLabel: string;
  onChange?: (value: number) => void;
  upperEndLabel: string;
  value?: number;
}) => (
  <div className="inline-grid grid-cols-[200px_max-content_175px] items-center">
    <Text className="mr-3 justify-self-end" italic>
      {lowerEndLabel}
    </Text>
    <Radio.Group
      buttonStyle="solid"
      onChange={(event) => onChange(event.target.value)}
      value={value}
    >
      <Radio.Button value={1}>
        <b>1</b>
      </Radio.Button>
      <Radio.Button value={2}>
        <b>2</b>
      </Radio.Button>
      <Radio.Button value={3}>
        <b>3</b>
      </Radio.Button>
      <Radio.Button value={4}>
        <b>4</b>
      </Radio.Button>
      <Radio.Button value={5}>
        <b>5</b>
      </Radio.Button>
    </Radio.Group>
    <Text className="ml-3" italic>
      {upperEndLabel}
    </Text>
  </div>
);

const OpenQuestion = ({ name, question }: { name: string; question: ReactNode }) => {
  return (
    <Form.Item label={<Title level={4}>{question}</Title>} name={name} wrapperCol={{ offset: 1 }}>
      <Input.TextArea className="!w-1/2" />
    </Form.Item>
  );
};

export default InteractionQuestionnaire;

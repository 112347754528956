import { BulbOutlined, QuestionOutlined } from '@ant-design/icons';
import { Button, Form, Modal, notification, Typography } from 'antd';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { usePrevious, useWindowSize } from 'react-use';
import styled from 'styled-components';
import { NoteElementSelection } from '../App';
import { minimumHeight, minimumWidth } from '../constants';
import useMusicSheet from '../hooks/useMusicSheet';
import useMusicSheets from '../hooks/useMusicSheets';
import { occursFirstInVoice } from '../hooks/usePatterns';
import useVoices from '../hooks/useVoices';
import MelodyAnalysis, { BeamStemElementSelection } from '../MelodyAnalysis';
import MelodyGraph from '../MelodyGraph';
import MusicSheet from '../models/MusicSheet';
import { Occurrence, Operator, Pattern } from '../models/Pattern';
import MusicSheetSelection from '../MusicSheetSelection';
import OnBoarding from '../OnBoarding';
import { OperatorCondition, TLXQuestionnaire, useStudy, useStudyDispatch } from '../StudyContext';
import Suggestions from '../Suggestions';
import { ThemeContext } from '../ThemeContext';
import Timeline from '../Timeline';

export enum AnalysisTask {
  PLAYGROUND = '🛝 Playground',
  TASK_1 = '🎼 Task 1',
  TASK_2 = '🎼 Task 2'
}

const getDefaultPatterns = (selectedMusicSheet: MusicSheet): Pattern[] => {
  if (selectedMusicSheet?.title === 'Fugue No. 20 in A minor (BWV 889)') {
    return [
      {
        color: 'orange',
        connectedToByOccurrences: [],
        connectedToByOperator: [],
        description: '',
        hasBeenQueried: true,
        id: 'A',
        isLocked: false,
        isUserDefined: true,
        isVisible: true,
        label: 'A',
        length: 9,
        occurrences: [
          {
            firstOffset: 21.0,
            lastOffset: 28.0,
            voiceNumber: 1
          },
          {
            firstOffset: 81.0,
            lastOffset: 88.0,
            voiceNumber: 1
          },
          {
            firstOffset: 1.0,
            lastOffset: 8.0,
            voiceNumber: 2
          },
          {
            firstOffset: 35.0,
            lastOffset: 42.0,
            voiceNumber: 3
          },
          {
            firstOffset: 99.0,
            lastOffset: 106.0,
            voiceNumber: 3
          },
          {
            firstOffset: 49.0,
            lastOffset: 56.0,
            voiceNumber: 1
          },
          {
            firstOffset: 9.0,
            lastOffset: 16.0,
            voiceNumber: 1
          },
          {
            firstOffset: 2.0,
            lastOffset: 8.0,
            voiceNumber: 2
          }
        ],
        uniqueOccurrencesCount: 8,
        voiceNumber: 2
      },
      {
        color: 'cyan',
        connectedToByOccurrences: [],
        connectedToByOperator: [],
        description: '',
        hasBeenQueried: true,
        id: 'B',
        isLocked: false,
        isUserDefined: true,
        isVisible: true,
        label: 'B',
        length: 21,
        occurrences: [
          {
            firstOffset: 36.5,
            lastOffset: 42.0,
            voiceNumber: 1
          },
          {
            firstOffset: 22.5,
            lastOffset: 28.0,
            voiceNumber: 2
          },
          {
            firstOffset: 50.5,
            lastOffset: 56.0,
            voiceNumber: 2
          },
          {
            firstOffset: 10.5,
            lastOffset: 16.0,
            voiceNumber: 2
          },
          {
            firstOffset: 66.5,
            lastOffset: 72.0,
            voiceNumber: 3
          },
          {
            firstOffset: 82.5,
            lastOffset: 88.0,
            voiceNumber: 3
          },
          {
            firstOffset: 88.0,
            lastOffset: 92.0,
            voiceNumber: 3
          }
        ],
        uniqueOccurrencesCount: 7,
        voiceNumber: 3
      },
      {
        color: 'darkred',
        connectedToByOccurrences: [],
        connectedToByOperator: [],
        description: '',
        hasBeenQueried: true,
        id: 'C',
        isLocked: false,
        isUserDefined: true,
        isVisible: true,
        label: 'C',
        length: 6,
        occurrences: [
          {
            firstOffset: 24.5,
            lastOffset: 28.0,
            voiceNumber: 3
          },
          {
            firstOffset: 52.5,
            lastOffset: 56.0,
            voiceNumber: 3
          },
          {
            firstOffset: 38.5,
            lastOffset: 41.0,
            voiceNumber: 2
          },
          {
            firstOffset: 68.5,
            lastOffset: 71.0,
            voiceNumber: 1
          },
          {
            firstOffset: 84.5,
            lastOffset: 87.0,
            voiceNumber: 2
          },
          {
            firstOffset: 88.5,
            lastOffset: 91.0,
            voiceNumber: 2
          }
        ],
        uniqueOccurrencesCount: 6,
        voiceNumber: 2
      }
    ];
  } else if (selectedMusicSheet?.title === 'The Silver Swan') {
    return [
      {
        color: 'darkred',
        connectedToByOccurrences: [],
        connectedToByOperator: [],
        description: '',
        hasBeenQueried: true,
        id: 'A',
        isLocked: false,
        isUserDefined: true,
        isVisible: true,
        label: 'A',
        length: 5,
        occurrences: [
          {
            firstOffset: 11.0,
            lastOffset: 14.0,
            voiceNumber: 2
          },
          {
            firstOffset: 12.0,
            lastOffset: 15.0,
            voiceNumber: 5
          },
          {
            firstOffset: 32.0,
            lastOffset: 35.0,
            voiceNumber: 2
          },
          {
            firstOffset: 60.0,
            lastOffset: 63.0,
            voiceNumber: 2
          },
          {
            firstOffset: 32.0,
            lastOffset: 35.0,
            voiceNumber: 3
          },
          {
            firstOffset: 60.0,
            lastOffset: 63.0,
            voiceNumber: 3
          },
          {
            firstOffset: 8.0,
            lastOffset: 11.0,
            voiceNumber: 4
          },
          {
            firstOffset: 13.0,
            lastOffset: 16.0,
            voiceNumber: 4
          },
          {
            firstOffset: 28.0,
            lastOffset: 31.0,
            voiceNumber: 4
          },
          {
            firstOffset: 56.0,
            lastOffset: 59.0,
            voiceNumber: 4
          }
        ],
        uniqueOccurrencesCount: 10,
        voiceNumber: 4
      },
      {
        color: 'lime',
        connectedToByOccurrences: [],
        connectedToByOperator: [],
        description: '',
        hasBeenQueried: true,
        id: 'B',
        isLocked: false,
        isUserDefined: true,
        isVisible: true,
        label: 'B',
        length: 5,
        occurrences: [
          {
            firstOffset: 12.5,
            lastOffset: 15.0,
            voiceNumber: 2
          },
          {
            firstOffset: 10.5,
            lastOffset: 13.0,
            voiceNumber: 4
          },
          {
            firstOffset: 13.5,
            lastOffset: 16.0,
            voiceNumber: 5
          }
        ],
        uniqueOccurrencesCount: 3,
        voiceNumber: 5
      },
      {
        color: 'lightpink',
        connectedToByOccurrences: [],
        connectedToByOperator: [],
        description: '',
        hasBeenQueried: true,
        id: 'C',
        isLocked: false,
        isUserDefined: true,
        isVisible: true,
        label: 'C',
        length: 4,
        occurrences: [
          {
            firstOffset: 29.0,
            lastOffset: 32.0,
            voiceNumber: 1
          },
          {
            firstOffset: 57.0,
            lastOffset: 60.0,
            voiceNumber: 1
          },
          {
            firstOffset: 25.0,
            lastOffset: 28.0,
            voiceNumber: 2
          },
          {
            firstOffset: 53.0,
            lastOffset: 56.0,
            voiceNumber: 2
          },
          {
            firstOffset: 27.0,
            lastOffset: 30.0,
            voiceNumber: 3
          },
          {
            firstOffset: 55.0,
            lastOffset: 58.0,
            voiceNumber: 3
          },
          {
            firstOffset: 25.0,
            lastOffset: 28.0,
            voiceNumber: 5
          },
          {
            firstOffset: 53.0,
            lastOffset: 56.0,
            voiceNumber: 5
          }
        ],
        uniqueOccurrencesCount: 8,
        voiceNumber: 5
      },
      {
        color: 'red',
        connectedToByOccurrences: [],
        connectedToByOperator: [],
        description: '',
        hasBeenQueried: true,
        id: 'D',
        isLocked: false,
        isUserDefined: true,
        isVisible: true,
        label: 'D',
        length: 22,
        occurrences: [
          {
            firstOffset: 29.0,
            lastOffset: 52.0,
            voiceNumber: 1
          },
          {
            firstOffset: 57.0,
            lastOffset: 80.0,
            voiceNumber: 1
          }
        ],
        uniqueOccurrencesCount: 2,
        voiceNumber: 1
      },
      {
        color: 'yellow',
        connectedToByOccurrences: [],
        connectedToByOperator: [],
        description: '',
        hasBeenQueried: true,
        id: 'E',
        isLocked: false,
        isUserDefined: true,
        isVisible: true,
        label: 'E',
        length: 29,
        occurrences: [
          {
            firstOffset: 25.0,
            lastOffset: 52.0,
            voiceNumber: 2
          },
          {
            firstOffset: 53.0,
            lastOffset: 80.0,
            voiceNumber: 2
          }
        ],
        uniqueOccurrencesCount: 2,
        voiceNumber: 2
      },
      {
        color: 'cyan',
        connectedToByOccurrences: [],
        connectedToByOperator: [],
        description: '',
        hasBeenQueried: true,
        id: 'F',
        isLocked: false,
        isUserDefined: true,
        isVisible: true,
        label: 'F',
        length: 27,
        occurrences: [
          {
            firstOffset: 27.0,
            lastOffset: 52.0,
            voiceNumber: 3
          },
          {
            firstOffset: 55.0,
            lastOffset: 80.0,
            voiceNumber: 3
          }
        ],
        uniqueOccurrencesCount: 2,
        voiceNumber: 3
      },
      {
        color: 'orange',
        connectedToByOccurrences: [],
        connectedToByOperator: [],
        description: '',
        hasBeenQueried: true,
        id: 'G',
        isLocked: false,
        isUserDefined: true,
        isVisible: true,
        label: 'G',
        length: 24,
        occurrences: [
          {
            firstOffset: 27.0,
            lastOffset: 52.0,
            voiceNumber: 4
          },
          {
            firstOffset: 55.0,
            lastOffset: 80.0,
            voiceNumber: 4
          }
        ],
        uniqueOccurrencesCount: 2,
        voiceNumber: 4
      },
      {
        color: 'darkorchid',
        connectedToByOccurrences: [],
        connectedToByOperator: [],
        description: '',
        hasBeenQueried: true,
        id: 'H',
        isLocked: false,
        isUserDefined: true,
        isVisible: true,
        label: 'H',
        length: 20,
        occurrences: [
          {
            firstOffset: 25.0,
            lastOffset: 52.0,
            voiceNumber: 5
          },
          {
            firstOffset: 53.0,
            lastOffset: 80.0,
            voiceNumber: 5
          }
        ],
        uniqueOccurrencesCount: 2,
        voiceNumber: 5
      }
    ];
  } else {
    return [];
  }
};
const windowSizeWarningNotificationKey = 'window-size-warning';
export const mainWrapperID = 'main-wrapper';
const { Paragraph, Title } = Typography;

const AnalysisTasks = ({ task }: { task: AnalysisTask }) => {
  const [absoluteOffsetPageMap, setAbsoluteOffsetPageMap] = useState<Map<number, number>>(
    new Map()
  );
  const [configuringID, setConfiguringID] = useState<string>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { height, width } = useWindowSize();
  const [hoveredOccurrenceIndex, setHoveredOccurrenceIndex] = useState<number>(undefined);
  const [hoveredOccurrences, setHoveredOccurrences] = useState<Occurrence[]>([]);
  const [hoveredPatternIDs, setHoveredPatternIDs] = useState<string[]>([]);
  const [isMusicSheetRendered, setIsMusicSheetRendered] = useState<boolean>(false);
  const [newPattern, setNewPattern] = useState<Pattern | undefined>(undefined);
  const { resumeCountdown, startCountdown, stopCountdown } = useStudyDispatch();
  const [noteBeamElements, setNoteBeamElements] = useState<BeamStemElementSelection>();
  const [noteElements, setNoteElements] = useState<NoteElementSelection>();
  const [noteStemElements, setNoteStemElements] = useState<BeamStemElementSelection>();
  const [numberOfPages, setNumberOfPages] = useState<number>();
  const previousHeight = usePrevious(height);
  const previousWidth = usePrevious(width);
  const [selectedMusicSheetID, setSelectedMusicSheetID] = useState<string>();
  const selectedMusicSheet = useMusicSheet(selectedMusicSheetID);
  const { setPatterns } = useStudyDispatch();
  const [showOnBoarding, setShowOnBoarding] = useState<boolean>(false);
  const study = useStudy();
  const [displayInstructions, setDisplayInstructions] = useState<boolean>(
    (task === AnalysisTask.TASK_1 && !study.tlxQuestionnaireFirstTask.isOpen) ||
      (task === AnalysisTask.TASK_2 && !study.tlxQuestionnaireSecondTask.isOpen)
  );
  const [suggestingID, setSuggestionsID] = useState<string>(undefined);
  const [theme, setTheme] = useContext(ThemeContext);
  const { t: translate } = useTranslation();
  const [visibleOperatorsByDefault, setVisibleOperatorsByDefault] = useState<Operator[]>([
    Operator.TRANSPOSITION
  ]);

  const musicSheets = useMusicSheets();
  const voices = useVoices(selectedMusicSheetID);

  useEffect(() => {
    if (
      (previousHeight >= minimumHeight && height < minimumHeight) ||
      (previousWidth >= minimumWidth && width < minimumWidth)
    ) {
      notification.warning({
        description: (
          <Trans
            i18nKey="study.analysisTasks.sizeWarningNotification.description"
            values={{
              height,
              recommendedHeight: minimumHeight,
              recommendedWidth: minimumWidth,
              width
            }}
          />
        ),
        duration: null,
        key: windowSizeWarningNotificationKey,
        message: translate('study.analysisTasks.sizeWarningNotification.title')
      });
    } else if (height >= minimumHeight && width >= minimumWidth) {
      notification.destroy();
    }
  }, [height, width]);

  // This is only suitable for the visualization of the ground truth.
  /* useEffect(() => {
   if (selectedMusicSheet !== undefined) {
   setPatterns(getDefaultPatterns(selectedMusicSheet));
   }
   }, [selectedMusicSheet]); */

  useEffect(() => {
    if (theme.colorByVoice) {
      // Retain the current colors of the patterns to re-use them after coloring by pattern, again.
      setTheme((oldTheme) => ({
        ...oldTheme,
        availablePatternColors: [
          ...oldTheme.availablePatternColors,
          ...study.patterns.map((pattern) => pattern.color).reverse()
        ]
      }));

      setPatterns((oldPatterns) => {
        oldPatterns.forEach(
          (oldPattern) => (oldPattern.color = theme.getVoiceColor(occursFirstInVoice(oldPattern)))
        );

        return [...oldPatterns];
      });
    } else {
      setPatterns((oldPatterns) => {
        oldPatterns.forEach((oldPattern) => {
          if (theme.isVoiceColor(oldPattern.color)) {
            oldPattern.color = theme.availablePatternColors.pop();
          }
        });

        return [...oldPatterns];
      });
    }
  }, [theme.colorByVoice]);

  useEffect(() => {
    if (musicSheets.length > 0) {
      switch (task) {
        case AnalysisTask.PLAYGROUND:
          setSelectedMusicSheetID(
            musicSheets.find(
              (musicSheet) => musicSheet.title === 'Fugue No. 20 in A minor (BWV 889)'
            ).id
          );
          break;
        case AnalysisTask.TASK_1:
          setSelectedMusicSheetID(study.firstMusicSheetID);
          break;
        case AnalysisTask.TASK_2:
          setSelectedMusicSheetID(study.secondMusicSheetID);
          break;
      }
    }
  }, [musicSheets]);

  useEffect(() => {
    if (task === AnalysisTask.PLAYGROUND) return;

    if (displayInstructions || showOnBoarding) {
      stopCountdown();
    } else {
      resumeCountdown();
    }
  }, [displayInstructions, showOnBoarding]);

  useEffect(() => {
    if (
      absoluteOffsetPageMap.size > 0 &&
      (hoveredPatternIDs.length > 0 || hoveredOccurrences.length > 0) &&
      study.patterns.length > 0
    ) {
      let firstOffset;
      const hoveredPatterns = study.patterns.filter((pattern) =>
        hoveredPatternIDs.includes(pattern.id)
      );

      if (hoveredOccurrenceIndex !== undefined && hoveredPatterns.length === 1) {
        const hoveredOccurrence = hoveredPatterns[0].occurrences[hoveredOccurrenceIndex];

        firstOffset = hoveredOccurrence.firstOffset;
      } else if (hoveredOccurrences.length > 0) {
        firstOffset = Math.min(...hoveredOccurrences.map((occurrence) => occurrence.firstOffset));
      } else {
        firstOffset = Math.min(
          ...hoveredPatterns.flatMap((hoveredPattern) =>
            hoveredPattern.occurrences.map((occurrence) => occurrence.firstOffset)
          )
        );
      }

      const occurrencePage = absoluteOffsetPageMap.get(firstOffset);
      if (occurrencePage !== undefined && occurrencePage !== currentPage) {
        setCurrentPage(occurrencePage);
      }
    }
  }, [absoluteOffsetPageMap, hoveredOccurrenceIndex, hoveredOccurrences, hoveredPatternIDs]);

  return (
    <>
      <TaskDescription
        displayInstructions={displayInstructions}
        isMusicSheetRendered={isMusicSheetRendered}
        operatorCondition={
          (task === AnalysisTask.TASK_1 &&
            study.firstMusicSheetCondition === OperatorCondition.WITHOUT_OPERATORS) ||
          (task === AnalysisTask.TASK_2 &&
            study.secondMusicSheetCondition === OperatorCondition.WITHOUT_OPERATORS)
            ? OperatorCondition.WITHOUT_OPERATORS
            : OperatorCondition.WITH_OPERATORS
        }
        setDisplayInstructions={setDisplayInstructions}
        startCountdown={startCountdown}
        task={task}
      />
      {(task === AnalysisTask.TASK_1 || task === AnalysisTask.TASK_2) && (
        <NASATLXQuestionnaire task={task} />
      )}
      <OnBoarding isVisible={showOnBoarding} setIsVisible={setShowOnBoarding} />
      <div
        className="h-full grid gap-x-6"
        id={mainWrapperID}
        style={{ gridTemplateColumns: 'repeat(2, calc(50% - 0.75rem)' }}
      >
        <div className="flex flex-col justify-between">
          <MusicSheetSelection
            currentPage={currentPage}
            isMusicSheetRendered={isMusicSheetRendered}
            musicSheets={musicSheets}
            numberOfPages={numberOfPages}
            selectedMusicSheetID={selectedMusicSheetID}
            setCurrentPage={setCurrentPage}
            setShowOnBoarding={setShowOnBoarding}
            showHelp={() => setDisplayInstructions(true)}
            title={translate(`study.analysisTasks.${task}`)}
          />
          <MelodyAnalysis
            currentPage={currentPage}
            hoveredOccurrenceIndex={hoveredOccurrenceIndex}
            hoveredOccurrences={hoveredOccurrences}
            hoveredPatternIDs={hoveredPatternIDs}
            isMusicSheetRendered={isMusicSheetRendered}
            noteBeamElements={noteBeamElements}
            noteElements={noteElements}
            noteStemElements={noteStemElements}
            numberOfPages={numberOfPages}
            patterns={study.patterns}
            selectedMusicSheet={selectedMusicSheet}
            setAbsoluteOffsetPageMap={setAbsoluteOffsetPageMap}
            setConfiguringID={setConfiguringID}
            setCurrentPage={setCurrentPage}
            setHoveredOccurrenceIndex={setHoveredOccurrenceIndex}
            setHoveredPatternIDs={setHoveredPatternIDs}
            setIsMusicSheetRendered={setIsMusicSheetRendered}
            setNewPattern={setNewPattern}
            setNoteBeamElements={setNoteBeamElements}
            setNoteElements={setNoteElements}
            setNoteStemElements={setNoteStemElements}
            setNumberOfPages={setNumberOfPages}
            setPatterns={setPatterns}
            setSuggestingID={setSuggestionsID}
            suggestingID={suggestingID}
            voices={voices}
          />
        </div>
        <div className="flex flex-col justify-between">
          <Timeline
            absoluteOffsetPageMap={absoluteOffsetPageMap}
            hoveredOccurrenceIndex={hoveredOccurrenceIndex}
            hoveredOccurrences={hoveredOccurrences}
            hoveredPatternIDs={hoveredPatternIDs}
            patterns={study.patterns}
            selectedMusicSheet={selectedMusicSheet}
            setConfiguringID={setConfiguringID}
            setCurrentPage={setCurrentPage}
            setHoveredOccurrenceIndex={setHoveredOccurrenceIndex}
            setHoveredPatternIDs={setHoveredPatternIDs}
            voices={voices}
          />
          <div
            className="grid grid-cols-[fit-content(175px)_4fr] grid-x-2"
            style={{ height: '-webkit-fill-available' }}
          >
            <Suggestions
              configuringID={configuringID}
              hoveredPatternIDs={hoveredPatternIDs}
              musicSheetID={selectedMusicSheetID}
              noteElements={noteElements}
              newPattern={newPattern}
              patterns={study.patterns}
              setConfiguringID={setConfiguringID}
              setHoveredPatternIDs={setHoveredPatternIDs}
              setNewPattern={setNewPattern}
              setPatterns={setPatterns}
              setSuggestingID={setSuggestionsID}
              setVisibleOperatorsByDefault={setVisibleOperatorsByDefault}
              suggestingID={suggestingID}
              visibleOperatorsByDefault={visibleOperatorsByDefault}
            />
            <MelodyGraph
              configuringID={configuringID}
              hoveredOccurrences={hoveredOccurrences}
              hoveredPatternIDs={hoveredPatternIDs}
              patterns={study.patterns}
              setConfiguringID={setConfiguringID}
              setHoveredOccurrenceIndex={setHoveredOccurrenceIndex}
              setHoveredOccurrences={setHoveredOccurrences}
              setHoveredPatternIDs={setHoveredPatternIDs}
              setPatterns={setPatterns}
              setSuggestingID={setSuggestionsID}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const TaskDescription = ({
  displayInstructions,
  isMusicSheetRendered,
  operatorCondition,
  setDisplayInstructions,
  startCountdown,
  task
}: {
  displayInstructions: boolean;
  isMusicSheetRendered: boolean;
  operatorCondition: OperatorCondition;
  setDisplayInstructions: Dispatch<SetStateAction<boolean>>;
  startCountdown: () => void;
  task: AnalysisTask;
}) => {
  const { t: translate } = useTranslation();

  return (
    <Modal
      centered
      closable={false}
      footer={
        <Button
          loading={!isMusicSheetRendered}
          onClick={() => {
            setDisplayInstructions(false);

            if (task !== AnalysisTask.PLAYGROUND) startCountdown();
          }}
          type="primary"
        >
          {translate('study.analysisTasks.taskModal.button')}
        </Button>
      }
      open={displayInstructions}
      title={translate(`study.analysisTasks.${task}`)}
    >
      {task === AnalysisTask.PLAYGROUND && <PlaygroundDescription />}
      {task === AnalysisTask.TASK_1 && (
        <PartialTaskDescription operatorCondition={operatorCondition} task={task} />
      )}
      {task === AnalysisTask.TASK_2 && (
        <PartialTaskDescription operatorCondition={operatorCondition} task={task} />
      )}
      <p className="!mb-0">
        <b>{translate('study.analysisTasks.taskModal.technicalDisclaimer')}</b>
      </p>
    </Modal>
  );
};

const PlaygroundDescription = () => (
  <>
    <p>
      <Trans
        components={[<Button icon={<BulbOutlined />} shape="circle" size="small" />]}
        i18nKey="study.analysisTasks.taskModal.playground.description"
      ></Trans>
    </p>
    <p>
      <Trans
        components={[
          <Button size="small" type="primary" />,
          <Button icon={<QuestionOutlined />} shape="circle" size="small" />
        ]}
        i18nKey="study.analysisTasks.taskModal.playground.explanation"
      ></Trans>
    </p>
  </>
);

const PartialTaskDescription = ({
  operatorCondition,
  task
}: {
  operatorCondition: OperatorCondition;
  task: AnalysisTask;
}) => {
  const { t: translate } = useTranslation();
  const taskDesignation = task === AnalysisTask.TASK_1 ? 'task1' : 'task2';

  return (
    <>
      <p>{translate(`study.analysisTasks.taskModal.${taskDesignation}.description`)}</p>
      <SuggestionsDisclaimer operatorCondition={operatorCondition} />
      <p>
        <Trans
          components={[<Button size="small" type="primary" />]}
          i18nKey={`study.analysisTasks.taskModal.${taskDesignation}.explanation`}
        ></Trans>
      </p>
    </>
  );
};

const SuggestionsDisclaimer = ({ operatorCondition }: { operatorCondition: OperatorCondition }) => {
  const { t: translate } = useTranslation();

  if (operatorCondition === OperatorCondition.WITH_OPERATORS) {
    return <p>{translate('study.analysisTasks.taskModal.suggestionsDisclaimer.withOperators')}</p>;
  } else {
    return (
      <p>
        <Trans
          components={[<i />]}
          i18nKey="study.analysisTasks.taskModal.suggestionsDisclaimer.withoutOperators"
        ></Trans>
      </p>
    );
  }
};

const ScrollableModal = styled(Modal)`
  .ant-modal-body {
    height: calc(100% - 55px);
    overflow-y: auto;
    padding: 0;
  }

  .ant-modal-content {
    height: 100%;
  }
`;

const NASATLXQuestionnaire = ({ task }: { task: AnalysisTask }) => {
  const { nextStep, setTLXQuestionnaire } = useStudyDispatch();
  const study = useStudy();

  const tlxQuestionnaire =
    task === AnalysisTask.TASK_1
      ? study.tlxQuestionnaireFirstTask
      : study.tlxQuestionnaireSecondTask;

  const [areAllRequiredFieldsFilled, setAreAllRequiredFieldsFilled] = useState(
    Object.entries(tlxQuestionnaire || {}).every(([, value]) => value !== undefined)
  );
  const { t: translate } = useTranslation();

  return (
    <ScrollableModal
      centered
      closable={false}
      footer={null}
      maskClosable={false}
      open={tlxQuestionnaire.isOpen}
      style={{ height: '75%', overflow: 'hidden' }}
      title={<>📝 {translate(`study.tlxQuestionnaire.title`)}</>}
      width="50%"
    >
      <Form
        className="!mx-auto max-w-fit !p-4 !pb-0"
        fields={Object.entries(tlxQuestionnaire || {}).map(([name, value]) => ({
          name: [name],
          touched: value !== undefined,
          value
        }))}
        layout="vertical"
        onFieldsChange={(changedFields, allFields) => {
          if (allFields.every((field) => field.touched)) setAreAllRequiredFieldsFilled(true);

          const values = allFields.reduce((result, field) => {
            result[field.name[0]] = field.value;

            return result;
          }, {} as TLXQuestionnaire);
          values.isOpen = true;

          setTLXQuestionnaire(values, task);
        }}
        onFinish={(values) => {
          values.isOpen = true;

          setTLXQuestionnaire(values, task);
          nextStep();
        }}
      >
        <Paragraph className="!mb-6">{translate('study.tlxQuestionnaire.description')}</Paragraph>
        <TLXItem
          name="mentalDemand"
          title={translate('study.tlxQuestionnaire.mentalDemandTitle')}
          description={translate('study.tlxQuestionnaire.mentalDemand')}
          lowerEndLabel={translate('study.tlxQuestionnaire.veryLow')}
          upperEndLabel={translate('study.tlxQuestionnaire.veryHigh')}
        />
        <TLXItem
          name="physicalDemand"
          title={translate('study.tlxQuestionnaire.physicalDemandTitle')}
          description={translate('study.tlxQuestionnaire.physicalDemand')}
          lowerEndLabel={translate('study.tlxQuestionnaire.veryLow')}
          upperEndLabel={translate('study.tlxQuestionnaire.veryHigh')}
        />
        <TLXItem
          name="temporalDemand"
          title={translate('study.tlxQuestionnaire.temporalDemandTitle')}
          description={translate('study.tlxQuestionnaire.temporalDemand')}
          lowerEndLabel={translate('study.tlxQuestionnaire.veryLow')}
          upperEndLabel={translate('study.tlxQuestionnaire.veryHigh')}
        />
        <TLXItem
          name="performance"
          title={translate('study.tlxQuestionnaire.performanceTitle')}
          description={translate('study.tlxQuestionnaire.performance')}
          lowerEndLabel={translate('study.tlxQuestionnaire.performanceLow')}
          upperEndLabel={translate('study.tlxQuestionnaire.performanceHigh')}
        />
        <TLXItem
          name="effort"
          title={translate('study.tlxQuestionnaire.effortTitle')}
          description={translate('study.tlxQuestionnaire.effort')}
          lowerEndLabel={translate('study.tlxQuestionnaire.veryLow')}
          upperEndLabel={translate('study.tlxQuestionnaire.veryHigh')}
        />
        <TLXItem
          name="frustration"
          title={translate('study.tlxQuestionnaire.frustrationTitle')}
          description={translate('study.tlxQuestionnaire.frustration')}
          lowerEndLabel={translate('study.tlxQuestionnaire.veryLow')}
          upperEndLabel={translate('study.tlxQuestionnaire.veryHigh')}
        />
        <Form.Item>
          <Button
            className="float-right"
            disabled={!areAllRequiredFieldsFilled}
            htmlType="submit"
            type="primary"
          >
            {translate('study.tlxQuestionnaire.button')}
          </Button>
        </Form.Item>
      </Form>
    </ScrollableModal>
  );
};

const TLXItem = ({
  name,
  title,
  description,
  lowerEndLabel,
  upperEndLabel
}: {
  name: string;
  title: string;
  description: string;
  lowerEndLabel: string;
  upperEndLabel: string;
}) => (
  <Form.Item
    label={<Title level={5}>{title}</Title>}
    name={name}
    required
    wrapperCol={{ offset: 1 }}
  >
    <ValueWrapper
      description={description}
      lowerEndLabel={lowerEndLabel}
      upperEndLabel={upperEndLabel}
    />
  </Form.Item>
);

/*
 * This wrapper is necessary to enable customized components in `Form` through an event listener,
 * i.e., `onChange`, and a way to pass the value to the `Radio.Group`.
 */
const ValueWrapper = ({
  description,
  lowerEndLabel,
  onChange,
  upperEndLabel,
  value
}: {
  description: string;
  lowerEndLabel: string;
  onChange?: (value: number) => void;
  upperEndLabel: string;
  value?: number;
}) => {
  const { t: translate } = useTranslation();

  return (
    <div className="w-[521px]">
      <div className="mb-2 -ml-4 -mt-1 text-gray-400">{description}</div>
      <div
        className="inline-grid"
        style={{ gridTemplateColumns: 'repeat(20, 1px 25px) 1px', gridTemplateRows: '40px' }}
      >
        {[...Array(41).keys()].map((tick) => {
          if (tick % 2 === 0) {
            let height = 15;

            if (tick === 20) {
              height = 40;
            } else if (tick % 4 === 0) {
              height = 25;
            }

            return <div className="w-[1px] self-end bg-black" key={tick} style={{ height }} />;
          } else {
            const tickAsValue = ((tick + 1) / 2) * 5;

            return (
              <div
                className={`border border-solid border-l-0 border-r-0 border-t-0 ${
                  value === tickAsValue ? 'bg-gray-500' : 'hover:bg-gray-300'
                } cursor-pointer`}
                key={tick}
                onClick={() => onChange(tickAsValue)}
              />
            );
          }
        })}
      </div>
      <div className="flex justify-between italic">
        <span>{translate(lowerEndLabel)}</span>
        <span>{translate(upperEndLabel)}</span>
      </div>
    </div>
  );
};

export default AnalysisTasks;

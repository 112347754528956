import { BulbOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import introductionVideoDE from '../../assets/videos/introductionDE.mp4';
import introductionVideoEN from '../../assets/videos/introductionEN.mp4';
import { getIconForOperator, getSnippetForOperator, OperatorsInOrder } from '../models/Pattern';
import { useStudyDispatch } from '../StudyContext';

const { Paragraph, Title } = Typography;

const Introduction = () => {
  const { nextStep } = useStudyDispatch();
  const { i18n, t: translate } = useTranslation();

  return (
    <div className="h-full w-full flex flex-col overflow-y-auto">
      <div className="mx-auto w-1/2">
        <Title className="!mt-8">🐣 {translate('study.introduction.title')}</Title>
        <Paragraph className="!mb-0">{translate('study.introduction.opening')}</Paragraph>
        <video
          className="mb-4 mt-4 w-full"
          controls
          src={i18n.language === 'de' ? introductionVideoDE : introductionVideoEN}
        />
        <Paragraph className="!mb-0">{translate('study.introduction.explanation')}</Paragraph>
        <div className="mb-6 ml-4 mt-6 grid grid-cols-[32px_1fr] gap-x-2 gap-y-2.5">
          {OperatorsInOrder.map((operator) => {
            return (
              <Fragment key={operator}>
                <div>
                  <img
                    alt={operator}
                    className="p-0.5 border border-solid border-gray-300 rounded"
                    height={32}
                    src={getIconForOperator(operator)}
                    width={32}
                  />
                </div>
                <div className="mb-6 flex flex-col">
                  <span className="text-lg font-bold leading-none">
                    {translate(`suggestions.operators.${operator.toLowerCase()}.title`)}
                  </span>
                  <div className="mb-1 ml-1 mt-1 inline-grid grid-cols-[max-content_20px_1fr] gap-x-1 items-center justify-center">
                    <img className="justify-self-end" src={getSnippetForOperator(operator, 'in')} />
                    <span className="justify-self-center">➡️</span>
                    <img src={getSnippetForOperator(operator, 'out')} />
                  </div>
                  <span className="ml-1 text-sm">
                    {translate(`study.introduction.operators.${operator.toLowerCase()}`)}
                  </span>
                </div>
              </Fragment>
            );
          })}
        </div>
        <Paragraph>
          <Trans
            components={[<Button icon={<BulbOutlined />} shape="circle" size="small" />]}
            i18nKey="study.introduction.lightbulb"
          ></Trans>
        </Paragraph>
        <Button className="float-right" onClick={nextStep} type="primary">
          {translate('study.introduction.button')}
        </Button>
      </div>
    </div>
  );
};

export default Introduction;

export const containerHeight = 800;
export const defaultDeviationPercentage = 20;
export const defaultOpacity = 0.6;
export const defaultVoiceColor = '#808080';
export const fullOpacity = 1;
export const lowOpacity = 0.4;
export const lowerOpacity = 0.2;
export const maximumNodeRadius = 20;
export const minimumHeight = 1050;
export const minimumNodeRadius = 10;
export const minimumWidth = 1750;
export const timeToCompleteTask = 1000 * 60 * 10;

import { Button, Form, Input, Radio, Space, Switch, Typography } from 'antd';
import { debounce } from 'lodash';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  DemographicQuestionnaire as DemographicQuestionnaireType,
  useStudy,
  useStudyDispatch
} from '../StudyContext';
import { LikertScale } from './InteractionQuestionnaire';

const { Paragraph, Title } = Typography;

const DemographicQuestionnaire = () => {
  const study = useStudy();
  const [areRequiredFieldsFilled, setAreRequiredFieldsFilled] = useState(
    Object.entries(study.demographicQuestionnaire || {}).every(
      ([key, value]) => value !== undefined || key === 'contact'
    )
  );
  const { nextStep, setDemographicQuestionnaire } = useStudyDispatch();
  const { t: translate } = useTranslation();

  const onFieldsChange = debounce((changedFields, allFields) => {
    onFieldsChange.cancel();

    if (allFields.filter((field) => 'contact' !== field.name[0]).every((field) => field.touched))
      setAreRequiredFieldsFilled(true);

    setDemographicQuestionnaire(
      allFields.reduce((result, field) => {
        result[field.name[0]] = field.value;

        return result;
      }, {} as DemographicQuestionnaireType)
    );
  }, 750);

  return (
    <div className="h-full w-full flex flex-col overflow-y-auto">
      <div className="mx-auto w-1/2">
        <Title className="!mt-8">📝 {translate('study.demographicQuestionnaire.title')}</Title>
        <Paragraph className="!mb-12">
          {translate('study.demographicQuestionnaire.opening')}
        </Paragraph>
        <Form
          fields={Object.entries(study.demographicQuestionnaire || {}).map(([name, value]) => ({
            name: [name],
            touched: value !== undefined,
            value
          }))}
          layout="vertical"
          onFieldsChange={onFieldsChange}
          onFinish={(values) => {
            setDemographicQuestionnaire(values);
            nextStep();
          }}
        >
          <Form.Item
            label={
              <Title level={4}>{translate('study.demographicQuestionnaire.age.question')}</Title>
            }
            name="age"
            required
            wrapperCol={{ offset: 1 }}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="18 - 24">18 - 24</Radio>
                <Radio value="25 - 34">25 - 34</Radio>
                <Radio value="35 - 44">35 - 44</Radio>
                <Radio value="45 - 54">45 - 54</Radio>
                <Radio value="above 54">
                  {translate('study.demographicQuestionnaire.age.options.above54')}
                </Radio>
                <Radio value="prefer not to say">
                  {translate('study.demographicQuestionnaire.preferNotToSay')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={
              <Title level={4}>{translate('study.demographicQuestionnaire.gender.question')}</Title>
            }
            name="gender"
            required
            wrapperCol={{ offset: 1 }}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="female">
                  {translate('study.demographicQuestionnaire.gender.options.female')}
                </Radio>
                <Radio value="male">
                  {translate('study.demographicQuestionnaire.gender.options.male')}
                </Radio>
                <Radio value="diverse">
                  {translate('study.demographicQuestionnaire.gender.options.diverse')}
                </Radio>
                <Radio value="prefer not to say">
                  {translate('study.demographicQuestionnaire.preferNotToSay')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={
              <Title level={4}>
                {translate('study.demographicQuestionnaire.education.question')}
              </Title>
            }
            name="highestLevelOfEducation"
            required
            wrapperCol={{ offset: 1 }}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="high school">
                  {translate('study.demographicQuestionnaire.education.options.highSchool')}
                </Radio>
                <Radio value="bachelor">
                  {translate('study.demographicQuestionnaire.education.options.bachelor')}
                </Radio>
                <Radio value="master">
                  {translate('study.demographicQuestionnaire.education.options.master')}
                </Radio>
                <Radio value="doctorate">
                  {translate('study.demographicQuestionnaire.education.options.doctorate')}
                </Radio>
                <Radio value="none">
                  {translate('study.demographicQuestionnaire.education.options.none')}
                </Radio>
                <Radio value="prefer not to say">
                  {translate('study.demographicQuestionnaire.preferNotToSay')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            help={
              <div className="mb-6 mt-3" style={{ maxWidth: '75%' }}>
                {translate('study.demographicQuestionnaire.dataVisualization.help')}
              </div>
            }
            label={
              <Title level={4}>
                {translate('study.demographicQuestionnaire.dataVisualization.question')}
              </Title>
            }
            name="experienceWithDataVisualization"
            required
            wrapperCol={{ offset: 1 }}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="0">
                  {translate('study.demographicQuestionnaire.dataVisualization.options.0')}
                </Radio>
                <Radio value="<1">
                  {translate('study.demographicQuestionnaire.dataVisualization.options.<1')}
                </Radio>
                <Radio value="1-3">
                  {translate('study.demographicQuestionnaire.dataVisualization.options.1-3')}
                </Radio>
                <Radio value="3-5">
                  {translate('study.demographicQuestionnaire.dataVisualization.options.3-5')}
                </Radio>
                <Radio value=">=5">
                  {translate('study.demographicQuestionnaire.dataVisualization.options.>=5')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            help={
              <div className="mb-6 mt-3" style={{ maxWidth: '75%' }}>
                <Trans
                  components={[<i />]}
                  i18nKey="study.demographicQuestionnaire.readSheetMusic.help"
                ></Trans>
              </div>
            }
            label={
              <Title level={4}>
                {translate('study.demographicQuestionnaire.readSheetMusic.question')}
              </Title>
            }
            name="isAbleToReadSheetMusic"
            required
            valuePropName="checked"
            wrapperCol={{ offset: 1 }}
          >
            <Switch
              checkedChildren={translate(
                'study.demographicQuestionnaire.readSheetMusic.options.yes'
              )}
              unCheckedChildren={translate(
                'study.demographicQuestionnaire.readSheetMusic.options.no'
              )}
            />
          </Form.Item>
          <Form.Item
            help={
              <div className="mb-6 mt-3" style={{ maxWidth: '75%' }}>
                <Trans
                  components={[<i />]}
                  i18nKey="study.demographicQuestionnaire.musicTheory.help"
                ></Trans>
              </div>
            }
            label={
              <Title level={4}>
                <Trans
                  components={[<u />]}
                  i18nKey="study.demographicQuestionnaire.musicTheory.question"
                ></Trans>
              </Title>
            }
            name="experienceWithMusicTheory"
            required
            wrapperCol={{ offset: 1 }}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="0">
                  {translate('study.demographicQuestionnaire.musicTheory.options.0')}
                </Radio>
                <Radio value="<1">
                  {translate('study.demographicQuestionnaire.musicTheory.options.<1')}
                </Radio>
                <Radio value="1-3">
                  {translate('study.demographicQuestionnaire.musicTheory.options.1-3')}
                </Radio>
                <Radio value="3-5">
                  {translate('study.demographicQuestionnaire.musicTheory.options.3-5')}
                </Radio>
                <Radio value=">=5">
                  {translate('study.demographicQuestionnaire.musicTheory.options.>=5')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            help={
              <div className="mb-6 mt-3" style={{ maxWidth: '75%' }}>
                {translate('study.demographicQuestionnaire.melodyAnalysis.help')}
              </div>
            }
            label={
              <Title level={4}>
                <Trans
                  components={[<u />]}
                  i18nKey="study.demographicQuestionnaire.melodyAnalysis.question"
                ></Trans>
              </Title>
            }
            name="experienceWithMelodyAnalysis"
            required
            wrapperCol={{ offset: 1 }}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="0">
                  {translate('study.demographicQuestionnaire.melodyAnalysis.options.0')}
                </Radio>
                <Radio value="<1">
                  {translate('study.demographicQuestionnaire.melodyAnalysis.options.<1')}
                </Radio>
                <Radio value="1-3">
                  {translate('study.demographicQuestionnaire.melodyAnalysis.options.1-3')}
                </Radio>
                <Radio value="3-5">
                  {translate('study.demographicQuestionnaire.melodyAnalysis.options.3-5')}
                </Radio>
                <Radio value=">=5">
                  {translate('study.demographicQuestionnaire.melodyAnalysis.options.>=5')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <LikertScale
            help={
              <div className="mb-6 mt-3" style={{ maxWidth: '75%' }}>
                <Trans
                  components={[<u />]}
                  i18nKey="study.demographicQuestionnaire.manualPatternAnnotation.help"
                ></Trans>
              </div>
            }
            lowerEndLabel={translate(
              'study.demographicQuestionnaire.manualPatternAnnotation.never'
            )}
            name="manualPatternAnnotation"
            question={translate('study.demographicQuestionnaire.manualPatternAnnotation.question')}
            upperEndLabel={translate(
              'study.demographicQuestionnaire.manualPatternAnnotation.often'
            )}
            withOffset
          />
          <Form.Item
            help={
              <div className="mb-6 mt-3" style={{ maxWidth: '75%' }}>
                <Trans
                  components={[<i />]}
                  i18nKey="study.demographicQuestionnaire.contact.help"
                ></Trans>
              </div>
            }
            label={
              <Title level={4}>
                {translate('study.demographicQuestionnaire.contact.question')}
              </Title>
            }
            name="contact"
            wrapperCol={{ offset: 1 }}
          >
            <Input className="!w-2/3" />
          </Form.Item>
          <Form.Item>
            <Button
              className="float-right"
              disabled={!areRequiredFieldsFilled}
              htmlType="submit"
              type="primary"
            >
              {translate('study.demographicQuestionnaire.button')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default DemographicQuestionnaire;

import { select, selectAll, Selection } from 'd3';
import {
  Note,
  OpenSheetMusicDisplay as OSMD,
  PointF2D,
  VexFlowGraphicalNote
} from 'opensheetmusicdisplay';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useAsync, useKey, useMeasure } from 'react-use';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { defaultOpacity, fullOpacity, lowerOpacity, lowOpacity } from './constants';
import MusicSheet from './models/MusicSheet';
import {
  getAbsoluteOffset,
  getChordSize,
  getDuration,
  getOffset,
  getPitch,
  isChord,
  NoteContext,
  translateAccidentalEnumToString,
  translateNoteEnumToString
} from './models/Note';
import { Occurrence, Pattern, PatternLabelGenerator } from './models/Pattern';
import { MeasureElement, Voice } from './models/Voice';
import { musicSheetHeaderID } from './MusicSheetSelection';
import PatternHighlight, { patternHighlightClass } from './PatternHighlight';
import { SelectionContext } from './SelectionContext';
import { mainWrapperID } from './study-control-steps/AnalysisTasks';
import { OperatorCondition, useStudy } from './StudyContext';
import { StudyStep } from './StudyControls';
import { ThemeContext } from './ThemeContext';

export type BeamStemElementSelection = Selection<SVGGElement, unknown, HTMLElement, any>;
type NoteElementSelection = Selection<SVGGElement, NoteContext, null, undefined>;
type PatternSegments = { color: string; id: string; segments: Segment[] };
type Point = [number, number];
type Segment = {
  occurrenceIndex: number;
  page: SVGElement;
  points: Point[];
};

export const musicSheetContainerID = 'music-sheet-container';
export const musicSheetPaginationClass = 'ul.ant-pagination';

const AnimatedCircle = styled.circle`
  cx: ${(styles) => styles.cx};
  cy: ${(styles) => styles.cy};
  transition-duration: 0.15s;
  transition-property: cx, cy;
`;

const MelodyAnalysis = ({
  currentPage,
  hoveredOccurrenceIndex,
  hoveredOccurrences,
  hoveredPatternIDs,
  isMusicSheetRendered,
  noteBeamElements,
  noteElements,
  noteStemElements,
  numberOfPages,
  patterns,
  selectedMusicSheet,
  setAbsoluteOffsetPageMap,
  setConfiguringID,
  setCurrentPage,
  setHoveredOccurrenceIndex,
  setHoveredPatternIDs,
  setIsMusicSheetRendered,
  setNewPattern,
  setNoteBeamElements,
  setNoteElements,
  setNoteStemElements,
  setNumberOfPages,
  setPatterns,
  setSuggestingID,
  suggestingID,
  voices
}: {
  currentPage: number;
  hoveredOccurrenceIndex: number | undefined;
  hoveredOccurrences: Occurrence[];
  hoveredPatternIDs: string[];
  isMusicSheetRendered: boolean;
  noteBeamElements: BeamStemElementSelection;
  noteElements: NoteElementSelection;
  noteStemElements: BeamStemElementSelection;
  numberOfPages: number;
  patterns: Pattern[];
  selectedMusicSheet: MusicSheet;
  setAbsoluteOffsetPageMap: Dispatch<SetStateAction<Map<number, number>>>;
  setConfiguringID: Dispatch<SetStateAction<string | undefined>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setHoveredOccurrenceIndex: Dispatch<SetStateAction<number | undefined>>;
  setHoveredPatternIDs: Dispatch<SetStateAction<string[]>>;
  setIsMusicSheetRendered: Dispatch<SetStateAction<boolean>>;
  setNewPattern: Dispatch<SetStateAction<Pattern>>;
  setNoteBeamElements: Dispatch<SetStateAction<BeamStemElementSelection>>;
  setNoteElements: Dispatch<SetStateAction<NoteElementSelection>>;
  setNoteStemElements: Dispatch<SetStateAction<BeamStemElementSelection>>;
  setNumberOfPages: Dispatch<SetStateAction<number>>;
  setPatterns: Dispatch<SetStateAction<Pattern[]>>;
  setSuggestingID: Dispatch<SetStateAction<string>>;
  suggestingID: string;
  voices: Voice[];
}) => {
  const numberOfSkeletonPlaceholders = 7;

  const [
    musicSheetContainerReference,
    { height: musicSheetContainerHeight, width: musicSheetContainerWidth }
  ] = useMeasure();
  const [hoveredNote, setHoveredNote] = useState<SVGGElement>();
  const [hoveredNoteContext, setHoveredNoteContext] = useState<NoteContext>();
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [noteBeamsOnCurrentPage, setNoteBeamsOnCurrentPage] = useState<BeamStemElementSelection>();
  const [noteElementsOnCurrentPage, setNoteElementsOnCurrentPage] =
    useState<NoteElementSelection>();
  const [noteStemsOnCurrentPage, setNoteStemsOnCurrentPage] = useState<BeamStemElementSelection>();
  const [openSheetMusicDisplay, setOpenSheetMusicDisplay] = useState<OSMD>();
  const [patternEndNoteContext, setPatternEndNoteContext] = useState<NoteContext>();
  const [patternHighlights, setPatternHighlights] = useState<PatternSegments[]>([]);
  const [patternStartNoteContext, setPatternStartNoteContext] = useState<NoteContext>();
  const [selection] = useContext(SelectionContext);
  const study = useStudy();
  const [theme] = useContext(ThemeContext);
  const [voiceColoringHitSet, setVoiceColoringHitSet] = useState<Set<MeasureElement>>(new Set());

  const highlightSelectedVoice = (noteContextForComparison: NoteContext) => {
    noteElementsOnCurrentPage.attr('opacity', (noteContext) =>
      noteContext.voiceNumber === noteContextForComparison.voiceNumber &&
      getAbsoluteOffset(noteContext.note.sourceNote) >=
        getAbsoluteOffset(noteContextForComparison.note.sourceNote)
        ? fullOpacity
        : lowerOpacity
    );

    const noteElementNumbersInPattern = noteElementsOnCurrentPage
      .filter(
        (noteContext) =>
          noteContext === noteContextForComparison ||
          (noteContext.voiceNumber === noteContextForComparison.voiceNumber &&
            getAbsoluteOffset(noteContext.note.sourceNote) >
              getAbsoluteOffset(noteContextForComparison.note.sourceNote))
      )
      .nodes()
      .map((node) => node.id.match(/\d+/)[0]);
    noteStemsOnCurrentPage.attr('opacity', function () {
      const thisNoteNumber = this.id.match(/\d+/)?.[0];

      return noteElementNumbersInPattern.includes(thisNoteNumber) ? fullOpacity : lowerOpacity;
    });

    noteBeamsOnCurrentPage.attr('opacity', lowerOpacity);
  };

  useAsync(async () => {
    if (selectedMusicSheet) {
      const musicSheetContainer = document.querySelector(
        `#${musicSheetContainerID}`
      ) as HTMLDivElement;
      musicSheetContainer.innerHTML = '';

      setIsMusicSheetRendered(false);
      setOpenSheetMusicDisplay(undefined);
      setConfiguringID(undefined);
      setCurrentPage(1);
      setHoveredNote(undefined);
      setHoveredNoteContext(undefined);
      setHoveredOccurrenceIndex(undefined);
      setHoveredPatternIDs([]);
      setNewPattern(undefined);
      setNoteBeamElements(undefined);
      setNoteBeamsOnCurrentPage(undefined);
      setNoteElements(undefined);
      setNoteElementsOnCurrentPage(undefined);
      setNoteStemElements(undefined);
      setNoteStemsOnCurrentPage(undefined);
      setNumberOfPages(undefined);
      setPatternStartNoteContext(undefined);
      setPatternEndNoteContext(undefined);
      setPatternHighlights([]);
      setSuggestingID(undefined);
      setVoiceColoringHitSet(new Set());
      PatternLabelGenerator.reset();

      await displayMusicSheet(selectedMusicSheet, musicSheetContainer, setOpenSheetMusicDisplay);
      setIsMusicSheetRendered(true);
    }
  }, [selectedMusicSheet]);

  useEffect(() => {
    if (musicSheetContainerHeight > 250 && musicSheetContainerWidth > 250) {
      setCurrentPage(1);
      setNumberOfPages(document.querySelectorAll('#music-sheet-container > div').length);
    }
  }, [musicSheetContainerHeight, musicSheetContainerWidth]);

  useEffect(() => {
    if (isMusicSheetRendered && numberOfPages && voices && !noteElements) {
      setNoteElements(
        getNoteElements(
          openSheetMusicDisplay,
          voices,
          voiceColoringHitSet,
          setAbsoluteOffsetPageMap,
          setVoiceColoringHitSet
        )
      );
      sizeMusicSheet(numberOfPages);
      addPatternHighlightContainers(numberOfPages);
    }
  }, [isMusicSheetRendered, numberOfPages, openSheetMusicDisplay, voices]);

  useEffect(() => {
    if (currentPage !== undefined && noteElements) {
      setNoteBeamElements(selectAll('svg[id^=osmdSvgPage] > g .vf-beam[id]'));
      setNoteStemElements(selectAll('svg[id^=osmdSvgPage] > g .vf-stem[id]'));
      setNoteBeamsOnCurrentPage(selectAll(`${getPageIDFromNumber(currentPage)} > g .vf-beam[id]`));
      setNoteElementsOnCurrentPage(
        noteElements.filter(function () {
          return this.ownerSVGElement.id === getPageNameFromNumber(currentPage);
        })
      );
      setNoteStemsOnCurrentPage(selectAll(`${getPageIDFromNumber(currentPage)} > g .vf-stem[id]`));
    }
  }, [currentPage, noteElements]);

  useEffect(() => {
    if (
      openSheetMusicDisplay &&
      noteBeamsOnCurrentPage &&
      noteElementsOnCurrentPage &&
      noteStemsOnCurrentPage &&
      patternStartNoteContext !== undefined &&
      patternEndNoteContext === undefined
    ) {
      highlightSelectedVoice(patternStartNoteContext);
    }
  }, [noteBeamsOnCurrentPage, noteElementsOnCurrentPage, noteStemsOnCurrentPage]);

  useEffect(() => {
    if (openSheetMusicDisplay && noteElementsOnCurrentPage) {
      if (selection.focusingID !== undefined) {
        select(getPageContentsSelector(currentPage)).on('click', null).on('mousemove', null);

        return;
      }

      const toDOM = (unitPoint: { x: number; y: number }) => {
        const svgPoint = new PointF2D(toPixels(unitPoint.x), toPixels(unitPoint.y));
        const domPoint = new DOMPoint(svgPoint.x, svgPoint.y);
        const transformMatrix = (
          document.querySelector(getPageIDFromNumber(currentPage)) as SVGSVGElement
        ).getScreenCTM();
        const translatedPoint = domPoint.matrixTransform(transformMatrix);

        return new PointF2D(translatedPoint.x, translatedPoint.y);
      };
      const toPixels = (units: number) =>
        openSheetMusicDisplay.GraphicSheet.drawer.calculatePixelDistance(units);

      select(getPageContentsSelector(currentPage))
        .on('click', () => {
          if (hoveredPatternIDs.length > 0) {
            /*
             * We want to configure the shortest pattern because it is the most difficult to interactively select.
             * The longer enclosing patterns can still be configured by hovering near the non-overlapping parts.
             */
            const shortestPatternID = getShortestPatternIDFromIDs(hoveredPatternIDs, patterns);
            setConfiguringID(shortestPatternID);

            return;
          }

          if (hoveredNoteContext === undefined) return;

          if (patternStartNoteContext === undefined) {
            setPatternStartNoteContext(hoveredNoteContext);
            highlightSelectedVoice(hoveredNoteContext);
          } else if (patternEndNoteContext === undefined) {
            setPatternEndNoteContext(hoveredNoteContext);
            setHoveredNote(undefined);
            setHoveredNoteContext(undefined);

            noteBeamElements.attr('opacity', fullOpacity);
            noteElements.attr('opacity', fullOpacity);
            noteStemElements.attr('opacity', fullOpacity);
          }
        })
        .on('mousemove', (event) => {
          const { x, y } = event;

          setIsHovering(true);

          const distancesToMouse = openSheetMusicDisplay.GraphicSheet.MusicPages[
            currentPage - 1
          ].MusicSystems.map((musicSystem) => {
            const systemPosition = musicSystem.PositionAndShape;

            const systemCenterX =
              systemPosition.AbsolutePosition.x +
              systemPosition.UpperLeftCorner.x +
              systemPosition.Size.width / 2;
            const systemCenterY =
              systemPosition.AbsolutePosition.y +
              systemPosition.UpperLeftCorner.y +
              systemPosition.BorderBottom / 2;
            const systemCenter = toDOM({ x: systemCenterX, y: systemCenterY });

            return Math.sqrt((x - systemCenter.x) ** 2 + (y - systemCenter.y) ** 2);
          });

          const closestMusicSystemIndex = distancesToMouse.indexOf(Math.min(...distancesToMouse));
          const systemPosition =
            openSheetMusicDisplay.GraphicSheet.MusicPages[currentPage - 1].MusicSystems[
              closestMusicSystemIndex
            ].PositionAndShape;
          const systemHeight = toPixels(systemPosition.BorderBottom);
          const systemWidth = toPixels(systemPosition.Size.width);
          const systemXY = toDOM({
            x: systemPosition.AbsolutePosition.x + systemPosition.UpperLeftCorner.x,
            y: systemPosition.AbsolutePosition.y + systemPosition.UpperLeftCorner.y
          });

          const notesInClosestSystem = noteElementsOnCurrentPage.filter(function () {
            const boundingRectangleNote = this.getBoundingClientRect();

            return !(
              boundingRectangleNote.bottom <= systemXY.y ||
              boundingRectangleNote.left >= systemXY.x + systemWidth ||
              boundingRectangleNote.top >= systemXY.y + systemHeight ||
              boundingRectangleNote.right <= systemXY.x
            );
          });
          const noteDistances = notesInClosestSystem.nodes().map((note) => {
            const { x: noteX, y: noteY } = note.getBoundingClientRect();

            return Math.sqrt((x - noteX) ** 2 + (y - noteY) ** 2);
          });
          const closestNoteIndex = noteDistances.indexOf(Math.min(...noteDistances));

          const hoveredNote = notesInClosestSystem.nodes()[closestNoteIndex];
          const hoveredNoteContext = notesInClosestSystem.data()[closestNoteIndex];

          if (hoveredNote === undefined || hoveredNoteContext === undefined) return;

          const hasNoVoice = hoveredNoteContext.voiceNumber === undefined;
          const hoveredNoteAbsoluteOffset = getAbsoluteOffset(hoveredNoteContext.note.sourceNote);
          const isRest = hoveredNoteContext.note.sourceNote.isRest();
          const enclosingPatterns = patterns
            .filter((pattern) => pattern.isVisible)
            .filter((pattern) =>
              pattern.occurrences.some(
                (occurrence) =>
                  occurrence.firstOffset <= hoveredNoteAbsoluteOffset &&
                  occurrence.lastOffset >= hoveredNoteAbsoluteOffset &&
                  occurrence.voiceNumber === hoveredNoteContext.voiceNumber
              )
            );

          if (
            (patternStartNoteContext === undefined ||
              (hoveredNoteContext.voiceNumber === patternStartNoteContext?.voiceNumber &&
                hoveredNoteAbsoluteOffset >
                  getAbsoluteOffset(patternStartNoteContext.note.sourceNote))) &&
            !hasNoVoice &&
            !isRest &&
            enclosingPatterns.length === 0
          ) {
            setHoveredNote(hoveredNote);
            setHoveredNoteContext(hoveredNoteContext);
            setHoveredOccurrenceIndex(undefined);
            setHoveredPatternIDs([]);
          } else if (!hasNoVoice && !isRest && enclosingPatterns.length > 0) {
            const absoluteOffset = getAbsoluteOffset(hoveredNoteContext.note.sourceNote);
            const shortestPatternID = getShortestPatternIDFromIDs(
              enclosingPatterns.map((pattern) => pattern.id),
              patterns
            );
            const shortestPattern = patterns.find((pattern) => pattern.id === shortestPatternID);

            if (shortestPattern) {
              setHoveredOccurrenceIndex(
                shortestPattern.occurrences.findIndex(
                  (occurrence) =>
                    occurrence.voiceNumber === hoveredNoteContext.voiceNumber &&
                    occurrence.firstOffset <= absoluteOffset &&
                    occurrence.lastOffset >= absoluteOffset
                )
              );
              setHoveredPatternIDs([shortestPatternID]);
            } else {
              setHoveredPatternIDs([]);
            }

            setHoveredNote(undefined);
            setHoveredNoteContext(undefined);
          } else if (hasNoVoice || isRest || enclosingPatterns.length > 0) {
            setHoveredNote(undefined);
            setHoveredNoteContext(undefined);
            setHoveredOccurrenceIndex(undefined);
            setHoveredPatternIDs([]);
          }
        })
        .on('mouseleave', () => {
          setHoveredNote(undefined);
          setHoveredNoteContext(undefined);
          setHoveredOccurrenceIndex(undefined);
          setHoveredPatternIDs([]);
          setIsHovering(false);
        });
    }
  }, [
    currentPage,
    hoveredNote,
    hoveredNoteContext,
    hoveredPatternIDs,
    noteBeamsOnCurrentPage,
    noteElementsOnCurrentPage,
    noteStemsOnCurrentPage,
    openSheetMusicDisplay,
    patterns,
    patternStartNoteContext,
    patternEndNoteContext,
    selection.focusingID
  ]);

  useKey(
    (event) => event.key === 'Escape',
    () => {
      if (patternStartNoteContext !== undefined && patternEndNoteContext === undefined) {
        setHoveredNote(undefined);
        setHoveredNoteContext(undefined);
        setPatternStartNoteContext(undefined);

        noteBeamElements.attr('opacity', fullOpacity);
        noteElements.attr('opacity', fullOpacity);
        noteStemElements.attr('opacity', fullOpacity);
      }
    },
    { event: 'keyup' }
  );

  useEffect(() => {
    if (openSheetMusicDisplay && noteElements) {
      noteElements
        .selectAll('g.vf-note g.vf-notehead path')
        // Replicate the note context for each path in the note, e.g., for each note in a chord.
        .data((noteContext, _, paths) => Array.from(paths).map(() => noteContext))
        .attr('fill', (noteContext) =>
          theme.colorVoices
            ? voices.find((voice) => voice.number === noteContext.voiceNumber)?.color
            : 'black'
        );

      if (theme.colorVoices) {
        selectAll(`.${patternHighlightClass}`).lower();
      } else {
        selectAll(`.${patternHighlightClass}`).raise();
      }
    }
  }, [theme.colorVoices, noteElements, openSheetMusicDisplay, voices]);

  useEffect(() => {
    if (selection.focusingID === undefined) {
      select(getPageContentsSelector(currentPage)).classed('cursor-default', false);
      select(getPageContentsSelector(currentPage)).classed('cursor-pointer', true);
    } else {
      select(getPageContentsSelector(currentPage)).classed('cursor-default', true);
      select(getPageContentsSelector(currentPage)).classed('cursor-pointer', false);
    }
  }, [selection.focusingID]);

  useEffect(() => {
    if (patternStartNoteContext !== undefined && patternEndNoteContext !== undefined) {
      const firstOffset = getAbsoluteOffset(patternStartNoteContext.note.sourceNote);
      const lastOffset = getAbsoluteOffset(patternEndNoteContext.note.sourceNote);
      const voiceNumber = patternStartNoteContext.voiceNumber;

      const noteContexts = noteElements
        .data()
        .filter((noteContext) => noteContext.voiceNumber === voiceNumber);
      const patternLength =
        noteContexts.findIndex((noteContext) => noteContext === patternEndNoteContext) -
        noteContexts.findIndex((noteContext) => noteContext === patternStartNoteContext) +
        1;
      const newPattern: Pattern = {
        color: theme.colorByVoice
          ? theme.getVoiceColor(voiceNumber)
          : theme.availablePatternColors.pop(),
        connectedToByOccurrences: [],
        connectedToByOperator: [],
        description: undefined,
        hasBeenQueried: false,
        id: uuidv4(),
        isLocked: false,
        isUserDefined: true,
        isVisible: true,
        label: PatternLabelGenerator.next(),
        length: patternLength,
        occurrences: [{ firstOffset, lastOffset, voiceNumber }],
        uniqueOccurrencesCount: 1,
        voiceNumber
      };

      setNewPattern(newPattern);

      if (
        (study.currentStep === StudyStep.ANALYSIS_TASK_1 &&
          study.firstMusicSheetCondition === OperatorCondition.WITH_OPERATORS) ||
        (study.currentStep === StudyStep.ANALYSIS_TASK_2 &&
          study.secondMusicSheetCondition === OperatorCondition.WITH_OPERATORS)
      ) {
        setPatterns((oldPatterns) => {
          if (suggestingID) {
            const suggestingPattern = oldPatterns.find(
              (oldPattern) => oldPattern.id === suggestingID
            );
            const suggestingPatternIndex = oldPatterns.findIndex(
              (oldPattern) => oldPattern.id === suggestingID
            );

            if (suggestingPattern) {
              suggestingPattern.connectedToByOperator.forEach(({ id: connectedPatternID }) => {
                const connectedPatternIndex = oldPatterns.findIndex(
                  (oldPattern) => oldPattern.id === connectedPatternID
                );

                if (connectedPatternIndex !== -1) oldPatterns.splice(connectedPatternIndex, 1);
              });
            }
            if (suggestingPatternIndex !== -1) {
              oldPatterns.splice(suggestingPatternIndex, 1);

              newPattern.label = suggestingPattern.label;
            }
          }

          return [...oldPatterns, newPattern];
        });
      } else {
        setPatterns((oldPatterns) => [...oldPatterns, newPattern]);
      }

      setConfiguringID(newPattern.id);
      setSuggestingID(newPattern.id);

      setPatternStartNoteContext(undefined);
      setPatternEndNoteContext(undefined);
    }
  }, [patternEndNoteContext, patternStartNoteContext]);

  useEffect(() => {
    if (openSheetMusicDisplay && noteElements && !theme.colorVoices) {
      setPatternHighlights(computePatternHighlights(noteElements, patterns, selection.focusingID));

      if (patterns.length === 0) PatternLabelGenerator.reset();
    }
  }, [noteElements, openSheetMusicDisplay, patterns, selection.focusingID]);

  useKey(
    (event) => event.key === 'ArrowRight',
    () => {
      if (currentPage < numberOfPages) {
        setCurrentPage(currentPage + 1);
      }
    },
    { event: 'keyup' }
  );

  useKey(
    (event) => event.key === 'ArrowLeft',
    () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    },
    { event: 'keyup' }
  );

  useEffect(() => {
    for (let pageNumber = 1; pageNumber <= numberOfPages; pageNumber++) {
      const pageContainer = document.querySelector(`#osmdCanvasPage${pageNumber}`);

      if (pageContainer === null) continue;

      (pageContainer as HTMLElement).style.order = String(
        pageNumber === currentPage ? -1 : pageNumber
      );
    }
  }, [currentPage, numberOfPages]);

  return (
    <div
      className={`overflow-hidden relative ${
        isMusicSheetRendered ? 'mt-4' : undefined
      } flex flex-col justify-center text-center`}
      style={{ flex: 10 }}
    >
      {!isMusicSheetRendered ? (
        <div className="flex flex-col items-center animate-pulse">
          {[...Array(numberOfSkeletonPlaceholders)].map((_, index) => (
            <div className="w-4/5 h-24 mb-3 mt-3 rounded-md bg-gray-200" key={index}></div>
          ))}
        </div>
      ) : undefined}
      <div
        className={`flex min-h-0 ${isMusicSheetRendered ? 'flex-1 mb-4' : 'h-0 invisible'}`}
        id={musicSheetContainerID}
        ref={musicSheetContainerReference}
      ></div>
      <HoveredNoteIndicator
        currentPage={currentPage}
        hoveredNote={hoveredNote}
        hoveredNoteContext={hoveredNoteContext}
      />
      {patternHighlights.map((patternHighlight, patternHighlightIndex) =>
        patternHighlight.segments.map((segment, segmentIndex) => (
          <PatternHighlight
            color={patternHighlight.color}
            colorOpacity={theme.colorVoices ? lowOpacity : defaultOpacity}
            coordinates={segment.points}
            hasEllipsisLeft={
              segmentIndex > 0 &&
              patternHighlight.segments[segmentIndex - 1].occurrenceIndex ===
                segment.occurrenceIndex
            }
            hasEllipsisRight={
              segmentIndex < patternHighlight.segments.length - 1 &&
              patternHighlight.segments[segmentIndex + 1].occurrenceIndex ===
                segment.occurrenceIndex
            }
            hoveredOccurrenceIndex={hoveredOccurrenceIndex}
            hoveredOccurrences={hoveredOccurrences}
            hoveredPatternIDs={hoveredPatternIDs}
            id={patternHighlight.id}
            key={`${patternHighlightIndex}${segmentIndex}`}
            occurrenceIndex={segment.occurrenceIndex}
            page={segment.page}
            pattern={patterns.find((pattern) => pattern.id === patternHighlight.id)}
            shouldShowTooltip={isHovering}
          />
        ))
      )}
    </div>
  );
};

const HoveredNoteIndicator = ({
  currentPage,
  hoveredNote,
  hoveredNoteContext
}: {
  currentPage: number;
  hoveredNote: SVGGElement;
  hoveredNoteContext: NoteContext;
}) => {
  const [theme] = useContext(ThemeContext);

  if (hoveredNote === undefined) return null;

  hoveredNote = hoveredNote.querySelector('g.vf-notehead path');
  const page = document.querySelector(getPageContentsSelector(currentPage));
  const { height, width, x, y } = hoveredNote.getBBox();

  if (page === null) return null;

  return createPortal(
    <AnimatedCircle
      cx={x + width / 2}
      cy={y + height / 2}
      fill={
        theme.colorByVoice
          ? theme.getVoiceColor(hoveredNoteContext.voiceNumber)
          : theme.availablePatternColors[theme.availablePatternColors.length - 1]
      }
      fillOpacity={defaultOpacity}
      r={10}
    ></AnimatedCircle>,
    page
  );
};

const addPatternHighlightContainers = (numberOfPages: number) => {
  for (let pageNumber = 1; pageNumber <= numberOfPages; pageNumber++) {
    const pageContent = document.querySelector(getPageContentsSelector(pageNumber));

    if (pageContent === null) continue;

    const patternHighlightContainer = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    patternHighlightContainer.id = `${getPageNameFromNumber(pageNumber)}-pattern-highlights`;

    pageContent.prepend(patternHighlightContainer);
  }
};

const computePatternHighlights = (
  noteElements: NoteElementSelection,
  patterns: Pattern[],
  focusingID: string | undefined
) => {
  if (patterns.length === 0) return [];

  const patternHighlights: Record<string, PatternSegments> = {};

  patterns
    .filter(
      (pattern) => pattern.isVisible && (focusingID === undefined || focusingID === pattern.id)
    )
    .forEach((pattern) => {
      pattern.occurrences.forEach((occurrence, occurrenceIndex) =>
        computePatternHighlightSegments(
          noteElements,
          pattern.color,
          patternHighlights,
          pattern.id,
          occurrence,
          occurrenceIndex
        )
      );
    });

  return Object.values(patternHighlights);
};

const computePatternHighlightSegments = (
  noteElements: NoteElementSelection,
  patternColor: string,
  patternHighlights: Record<number, PatternSegments>,
  patternID: string,
  occurrence: Occurrence,
  occurrenceIndex: number
) =>
  noteElements
    .filter((noteContext) => {
      const absoluteOffset = getAbsoluteOffset(noteContext.note.sourceNote);
      const matchesOffset =
        occurrence.firstOffset <= absoluteOffset && absoluteOffset <= occurrence.lastOffset;
      const occursInVoice = occurrence.voiceNumber === noteContext.voiceNumber;

      return occursInVoice && matchesOffset;
    })
    .sort(
      (firstNoteContext, secondNoteContext) =>
        getAbsoluteOffset(firstNoteContext.note.sourceNote) -
        getAbsoluteOffset(secondNoteContext.note.sourceNote)
    )
    .each((noteContext, index, nodes) => {
      const node = nodes[index];
      const page = document.getElementById(`${node.viewportElement.id}-pattern-highlights`);

      const boundingBox = (node.querySelector('g.vf-notehead path') as SVGPathElement).getBBox();
      const centerX = boundingBox.x + boundingBox.width / 2;
      const centerY = boundingBox.y + boundingBox.height / 2;
      const rightX = boundingBox.x + boundingBox.width;

      if (patternHighlights[patternID] !== undefined) {
        const numberOfSegments = patternHighlights[patternID].segments.length;
        const lastSegment = patternHighlights[patternID].segments[numberOfSegments - 1];

        if (
          lastSegment.points[lastSegment.points.length - 1][0] > rightX ||
          occurrenceIndex !==
            patternHighlights[patternID].segments[numberOfSegments - 1].occurrenceIndex
        ) {
          patternHighlights[patternID].segments.push({
            occurrenceIndex,
            page,
            points: [[centerX, centerY]]
          });
        } else {
          patternHighlights[patternID].segments[numberOfSegments - 1].points.push([
            centerX,
            centerY
          ]);
        }
      } else {
        patternHighlights[patternID] = {
          color: patternColor,
          id: patternID,
          segments: [
            {
              occurrenceIndex,
              page,
              points: [[centerX, centerY]]
            }
          ]
        };
      }
    });

const displayMusicSheet = async (
  musicSheet: MusicSheet | undefined,
  musicSheetContainer: HTMLDivElement,
  setOpenSheetMusicDisplay: Dispatch<SetStateAction<OSMD>>
) => {
  if (musicSheet) {
    const openSheetMusicDisplay: OSMD = new OSMD(musicSheetContainer, {
      autoResize: false,
      drawingParameters: 'compacttight',
      pageFormat: 'A4_P'
    });

    await openSheetMusicDisplay.load(musicSheet.musicXML);

    openSheetMusicDisplay.render();
    setOpenSheetMusicDisplay(openSheetMusicDisplay);
  }
};

const getNoteElements = (
  openSheetMusicDisplay: OSMD,
  voices: Voice[],
  voiceColoringHitSet: Set<MeasureElement>,
  setAbsoluteOffsetPageMap: Dispatch<SetStateAction<Map<number, number>>>,
  setVoiceColoringHitSet: Dispatch<SetStateAction<Set<MeasureElement>>>
) => {
  const absoluteOffsetPageMap = new Map<number, number>();
  const elements: SVGGElement[] = [];
  const noteContexts: NoteContext[] = [];

  openSheetMusicDisplay.GraphicSheet.MeasureList.forEach((system) => {
    system.forEach((measure) => {
      measure.staffEntries.forEach((staffEntry) => {
        staffEntry.graphicalVoiceEntries.forEach((graphicalVoiceEntry) => {
          graphicalVoiceEntry.notes.forEach((note) => {
            const vexFlowNote: VexFlowGraphicalNote = note as VexFlowGraphicalNote;
            const element: SVGGElement = vexFlowNote.vfnote[0].getAttribute('el');

            const voiceNumber = getVoiceNumber(
              voices,
              note.sourceNote,
              measure.MeasureNumber,
              voiceColoringHitSet
            );

            const pageNumber = element.ownerSVGElement.id.match(/\d+/)[0];
            const absoluteNoteOffset = getAbsoluteOffset(note.sourceNote);
            absoluteOffsetPageMap.set(absoluteNoteOffset, Number(pageNumber));

            elements.push(element);
            // FIXME: coloring does not work for chord notes other than the root.
            noteContexts.push({ note, voiceNumber });
          });
        });
      });
    });
  });

  setAbsoluteOffsetPageMap(absoluteOffsetPageMap);
  setVoiceColoringHitSet(new Set(voiceColoringHitSet));

  return selectAll(elements).data(noteContexts);
};

export const getPageContentsSelector = (pageNumber: number) =>
  `${getPageIDFromNumber(pageNumber)} > g`;
export const getPageIDFromNumber = (pageNumber: number) => `#${getPageNameFromNumber(pageNumber)}`;
const getPageNameFromNumber = (pageNumber: number) => `osmdSvgPage${pageNumber}`;

const getShortestPatternIDFromIDs = (patternIDs: string[], patterns: Pattern[]) =>
  patterns
    .filter((pattern) => patternIDs.includes(pattern.id))
    .sort((firstPattern, secondPattern) => firstPattern.length - secondPattern.length)[0].id;

const getVoiceNumber = (
  ofVoices: Voice[],
  forNote: Note,
  inMeasureNumber: number,
  voiceColoringHitSet: Set<MeasureElement>
) =>
  ofVoices.find((voice) =>
    voice.measures
      .filter((measure) => measure.number === inMeasureNumber)
      .some((measure) =>
        measure.elements.some((element) => {
          // We need the hit set to avoid elements with the same duration/offset/pitch being matched to the same voice.
          if (voiceColoringHitSet.has(element)) return false;

          if (isChord(forNote)) {
            if (Array.isArray(element.pitch) && getChordSize(forNote) === element.pitch.length) {
              const chordNotesAlign = forNote.ParentVoiceEntry.Notes.every((note, index) => {
                const notePitch = `${translateNoteEnumToString(
                  note.Pitch.FundamentalNote
                )}${translateAccidentalEnumToString(note.Pitch.Accidental)}${
                  note.Pitch.Octave + 3
                }`;
                const elementPitch = element.pitch[index];

                return notePitch === elementPitch;
              });

              if (!chordNotesAlign) return false;
            } else {
              return false;
            }
          }

          const forNoteDuration = getDuration(forNote);
          const forNoteOffset = getOffset(forNote);
          const forNotePitch = getPitch(forNote);

          const matchesDuration = forNote.IsGraceNote || forNoteDuration === element.duration;
          const matchesOffset = forNoteOffset === element.offset;
          const matchesPitch =
            Array.isArray(element.pitch) ||
            (forNote.isRest() && element.pitch === null) ||
            forNotePitch === element.pitch;

          const matches = matchesDuration && matchesOffset && matchesPitch;
          // For an unknown reason, chords are not colored correctly if included in the hit set.
          if (matches && !Array.isArray(element.pitch)) {
            voiceColoringHitSet.add(element);
          }

          return matches;
        })
      )
  )?.number;

const sizeMusicSheet = (numberOfPages: number) => {
  const pageHeight =
    document.querySelector(`#${mainWrapperID}`).clientHeight -
    document.querySelector(`#${musicSheetHeaderID}`).clientHeight;

  for (let pageNumber = 1; pageNumber <= numberOfPages; pageNumber++) {
    const pageContent = document.querySelector(getPageIDFromNumber(pageNumber));

    if (pageContent === null) continue;

    const boundingBox = (pageContent as SVGGraphicsElement).getBBox();
    const svgViewBox = (pageContent as SVGElement).getAttribute('viewBox').split(' ');

    (pageContent as SVGGraphicsElement).style.order = String(pageNumber);
    (pageContent as SVGGraphicsElement).setAttribute('height', pageHeight.toString());
    (pageContent as SVGGraphicsElement).setAttribute(
      'viewBox',
      `${svgViewBox[0]} ${svgViewBox[1]} ${svgViewBox[2]} ${boundingBox.height}`
    );

    // Group music sheet elements to decrease the part of the user interface that allows for note selection.
    const pageContents = Array.from(
      document.querySelectorAll(`${getPageIDFromNumber(pageNumber)} > *`)
    );
    // Remove elements from the page's SVG.
    pageContents.forEach((element) => element.remove());

    // Create a group that contains the music sheet elements.
    const pageContentsContainer = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    pageContentsContainer.classList.add('cursor-pointer', 'background-white', 'focus:outline-0');
    pageContentsContainer.style.pointerEvents = 'bounding-box';

    document.querySelector(getPageIDFromNumber(pageNumber)).append(pageContentsContainer);
    // Add elements to the page's container.
    document.querySelector(`${getPageIDFromNumber(pageNumber)} > g`).append(...pageContents);
  }
};

export default MelodyAnalysis;

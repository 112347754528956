import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';

interface Selection {
  focusingID: string | undefined;
}

const defaultSelection: Selection = {
  focusingID: undefined
};

export const SelectionContext = createContext<[Selection, Dispatch<SetStateAction<Selection>>]>([
  defaultSelection,
  undefined
]);

const SelectionContextProvider = ({ children }: { children: ReactNode }) => {
  const [selection, setSelection] = useState(defaultSelection);

  return (
    <SelectionContext.Provider value={[selection, setSelection]}>
      {children}
    </SelectionContext.Provider>
  );
};

export default SelectionContextProvider;

import { Button, Form, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SUSQuestionnaire, useStudy, useStudyDispatch } from '../StudyContext';
import { LikertScale } from './InteractionQuestionnaire';

const { Title } = Typography;

const SUSQuestionnaire = () => {
  const study = useStudy();
  const [areAllRequiredFieldsFilled, setAreAllRequiredFieldsFilled] = useState(
    Object.entries(study.susQuestionnaire || {}).every(([, value]) => value !== undefined)
  );

  const { nextStep, setSUSQuestionnaire } = useStudyDispatch();
  const { t: translate } = useTranslation();

  return (
    <div className="h-full w-full flex flex-col overflow-y-auto">
      <div className="mx-auto w-1/2">
        <Title className="!mt-12">📝 {translate('study.susQuestionnaire.title')}</Title>
        <Form
          fields={Object.entries(study.susQuestionnaire || {}).map(([name, value]) => ({
            name: [name],
            touched: value !== undefined,
            value
          }))}
          layout="vertical"
          onFieldsChange={(changedFields, allFields) => {
            if (allFields.every((field) => field.touched)) setAreAllRequiredFieldsFilled(true);

            setSUSQuestionnaire(
              allFields.reduce((result, field) => {
                result[field.name[0]] = field.value;

                return result;
              }, {} as SUSQuestionnaire)
            );
          }}
          onFinish={(values) => {
            setSUSQuestionnaire(values);
            nextStep();
          }}
        >
          {[
            'confident',
            'cumbersome',
            'easyToUse',
            'learnALot',
            'learnQuickly',
            'needAssistance',
            'tooMuchInconsistency',
            'unnecessarilyComplex',
            'useFrequently',
            'wellIntegrated'
          ].map((key) => (
            <LikertScale
              key={key}
              lowerEndLabel={translate('study.susQuestionnaire.veryLow')}
              name={key}
              question={translate(`study.susQuestionnaire.${key}`)}
              upperEndLabel={translate('study.susQuestionnaire.veryHigh')}
              withOffset
            />
          ))}
          <Form.Item>
            <Button
              className="float-right"
              disabled={!areAllRequiredFieldsFilled}
              htmlType="submit"
              type="primary"
            >
              {translate('study.susQuestionnaire.button')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default SUSQuestionnaire;
